import cubejs from "@cubejs-client/core";
import { getCubeJsToken } from "../../Api/backend";
import React, { ReactElement } from "react";
import { CubeProvider } from "@cubejs-client/react";

export const cubejsApi = cubejs(async () => await getCubeJsToken(), {
    apiUrl: process.env.REACT_APP_CUBEJS_BACKEND_API_URL as string,
});

interface Props {
    children: ReactElement;
}

export default function CubeJsProvider(props: Props): ReactElement {
    return <CubeProvider cubejsApi={cubejsApi}>{props.children}</CubeProvider>;
}
