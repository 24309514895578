import React, { Dispatch, ReactElement, SetStateAction } from "react";
import CheckboxWithDrawers from "./index";
import { Action, AddDaysToFilterAction } from "../../State/actions";
import { Context, State } from "../../State/store";
import { DAYS_OF_THE_WEEK } from "../../Utils/utils";
import { drawerType } from "../SideNavigation/CheckboxManager";
interface Props {
    openDrawerState: drawerType;
    setOpenDrawerState: Dispatch<SetStateAction<drawerType>>;
}

export default function DayCheckbox({
    openDrawerState,
    setOpenDrawerState,
}: Props): ReactElement {
    const [state, dispatch]: [State, Dispatch<Action>] = React.useContext(Context);
    const selectedDays = state.filters.selectedDays;

    const dispatchAction = (selectedDays: string[]) => {
        const action: AddDaysToFilterAction = {
            type: "ADD_DAYS_TO_FILTER",
            payload: {
                selectedDays,
            },
        };
        dispatch(action);
    };

    return (
        <>
            <CheckboxWithDrawers
                options={DAYS_OF_THE_WEEK}
                dispatchAction={dispatchAction}
                selectedOptions={selectedDays}
                openDrawerState={openDrawerState}
                setOpenDrawerState={setOpenDrawerState}
                drawerType={"Day"}
                title={"Days"}
            />
        </>
    );
}
