import moment, { Moment } from "moment";
import React, { ReactElement, useContext, useState } from "react";
import { DatePicker, Tag } from "antd";
import { Context, Filters, SelectedDates } from "../../State/store";
import styled from "styled-components";
import { UpdateFiltersAction } from "../../State/actions";
import { debounce } from "lodash";
import { DEFAULT_DATE_FORMAT } from "Utils/date-utils";
import { client } from "index";
import { cubejsApi } from "Components/CubeJsProvider";
import { prefetchTotalSalesAndStaffHours } from "Hooks/useGetTotalSalesAndStaffHoursByDates";
import { prefetchTotalSalesAndStaffHoursForComparison } from "Hooks/useGetTotalSalesAndStaffHoursForComparison";
const { RangePicker } = DatePicker;

export const FullWidthDatePicker = styled(RangePicker)`
    width: 100%;
`;

const DatePickerList = styled.ul`
    margin-bottom: 0;
    padding: 4px 12px;
    overflow: hidden;
    line-height: 34px;
    text-align: left;
    list-style: none;
`;

const DatePickerListItem = styled.li`
    display: inline-block;
`;

const DatePickerTag = styled(Tag)`
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
    cursor: pointer;
`;

const convertSelectedDatesIntoMoment = (
    selectedDates: SelectedDates
): [moment.Moment, moment.Moment] => {
    if (selectedDates.isPresetDateRange) {
        const selectedRange = presetRanges.find(
            (range) => range.label === selectedDates.presetDateRangeLabel
        )!;
        return selectedRange.range;
    }
    return [moment(selectedDates.fromDate), moment(selectedDates.toDate)];
};

interface PresetRange {
    label: string;
    range: [moment.Moment, moment.Moment];
}

export const presetRanges: PresetRange[] = [
    {
        label: "Today",
        range: [moment(), moment()],
    },
    {
        label: "Yesterday",
        range: [moment().subtract(1, "d"), moment().subtract(1, "d")],
    },
    {
        label: "This Week",
        range: [moment().startOf("isoWeek"), moment()],
    },
    {
        label: "Last Week",
        range: [
            moment().subtract(1, "w").startOf("isoWeek"),
            moment().subtract(1, "w").endOf("isoWeek"),
        ],
    },
    {
        label: "This Month",
        range: [moment().startOf("month"), moment()],
    },
    {
        label: "Last Month",
        range: [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
        ],
    },
    {
        label: "This Year",
        range: [moment().startOf("year"), moment()],
    },
    {
        label: "Last Year",
        range: [
            moment().subtract(1, "y").startOf("year"),
            moment().subtract(1, "y").endOf("year"),
        ],
    },
    {
        label: "Forever",
        range: [moment("2018-01-01"), moment()],
    },
];

export default function DateFilter(): ReactElement {
    const [state, dispatch] = useContext(Context);
    const { filters } = state;
    const { selectedDates }: Filters = filters;
    const [currentMode, setCurrentMode] = useState<any>(["date", "date"]);
    const [open, setOpen] = useState(false);
    const { selectedComparison, mode } = filters;

    const disabledDate = (current) => {
        return (
            current &&
            (current > moment().endOf("day") || current < moment("2018-01-01"))
        );
    };

    const onPanelChange = (value, mode) => {
        if (mode[0] === "decade") {
            mode[0] = "year";
        }
        setCurrentMode(mode);
    };

    const onChange = (dates, dateStrings: [string, string]) => {
        let selectedDates: SelectedDates = {
            fromDate: dateStrings[0],
            toDate: dateStrings[1],
        };

        if (dates === null) {
            const today = moment().format("YYYY-MM-DD");
            selectedDates = {
                fromDate: today,
                toDate: today,
            };
        }

        const { fromDate, toDate } = selectedDates;

        const action: UpdateFiltersAction = {
            type: "UPDATE_FILTERS",
            payload: {
                filters: {
                    ...state.filters,
                    selectedDates: {
                        fromDate,
                        toDate,
                        isPresetDateRange: false,
                    },
                    mode:
                        selectedComparison !== null && mode === "forecast"
                            ? mode
                            : "actual",
                } as Filters,
            },
        };
        dispatch(action);
        setCurrentMode(["date", "date"]);
    };

    const onClickPresetRange = (e) => {
        const target = e.target as HTMLElement;
        const rangeLabel = target.innerText;
        const selectedRange = presetRanges.find(
            (range) => range.label === rangeLabel
        )!;
        const [fromDateObj, toDateObj] = selectedRange.range;
        const fromDate = fromDateObj.format("YYYY-MM-DD");
        const toDate = toDateObj.format("YYYY-MM-DD");
        const action: UpdateFiltersAction = {
            type: "UPDATE_FILTERS",
            payload: {
                filters: {
                    ...state.filters,
                    selectedDates: {
                        fromDate,
                        toDate,
                        isPresetDateRange: true,
                        presetDateRangeLabel: selectedRange.label,
                    },
                    mode:
                        selectedComparison !== null && mode === "forecast"
                            ? mode
                            : "actual",
                } as Filters,
            },
        };
        dispatch(action);
        setOpen(false);
        setCurrentMode(["date", "date"]);
    };

    const handleMouseOverPresetDates = debounce(
        ([start, end]: [Moment, Moment]) => {
            const filtersToPrefetch = {
                ...filters,
                selectedDates: {
                    fromDate: start.format(DEFAULT_DATE_FORMAT),
                    toDate: end.format(DEFAULT_DATE_FORMAT),
                },
            };
            prefetchTotalSalesAndStaffHours(filtersToPrefetch);
            prefetchTotalSalesAndStaffHoursForComparison(filtersToPrefetch);
        },
        500,
        { leading: true }
    );

    return (
        <FullWidthDatePicker
            mode={currentMode}
            disabledDate={disabledDate}
            onPanelChange={onPanelChange}
            onChange={onChange}
            open={open}
            onOpenChange={() => setOpen(!open)}
            value={convertSelectedDatesIntoMoment(selectedDates)}
            renderExtraFooter={() => (
                <>
                    <DatePickerList>
                        {presetRanges.map((range) => (
                            <DatePickerListItem
                                key={range.label}
                                onMouseEnter={() =>
                                    handleMouseOverPresetDates(range.range)
                                }
                                onClick={onClickPresetRange}
                            >
                                <DatePickerTag>{range.label}</DatePickerTag>
                            </DatePickerListItem>
                        ))}
                    </DatePickerList>
                </>
            )}
        />
    );
}
