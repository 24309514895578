import styled from "styled-components";
import React, { ReactElement } from "react";
import { Context, State } from "../../State/store";
import { Layout, Image } from "antd";
import SideNavFilters from "./SideNavFilters";
import salesLineLogo from "salesline-logo.svg";
import { animated, config, useSpring } from "react-spring";
import { RoutePaths } from "../Router/Routes";

const StyledSider = styled(Layout.Sider)`
    background: white;
    border-right: 1px solid #d9d9d9;
`;

const AnimatedDiv = animated.div;

const PaddedFixedDiv = styled(AnimatedDiv)`
    padding: 20px;
    position: fixed;
    width: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
`;

const Logo = styled(Image)`
    width: 90%;
    margin: 0 auto 10px 40px;
`;

export default function SideNavigation(): ReactElement {
    const [state] = React.useContext(Context);
    const { groupData }: State = state;
    const styles = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 100,
        config: config.stiff,
    });

    return (
        <>
            {groupData ? (
                <StyledSider width={300}>
                    <PaddedFixedDiv style={styles}>
                        <a href={RoutePaths.HOME}>
                            <Logo src={salesLineLogo} preview={false} />
                        </a>
                        <SideNavFilters />
                    </PaddedFixedDiv>
                </StyledSider>
            ) : (
                <StyledSider width={300} />
            )}
        </>
    );
}
