import React, { ReactElement, useEffect, useState } from "react";
import ForecastingDashboardDesktop from "../Components/ForecastingDashboardDesktop";
import { useHistory } from "react-router-dom";
import { MOBILE_VIEWPORT_WIDTH } from "../Utils/constants";
import BaseDesktopPage from "./BaseDesktopPage";
import { RoutePaths } from "../Components/Router/Routes";

export default function Forecasting(): ReactElement {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);

        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const history = useHistory();

    useEffect(() => {
        const isMobile = windowWidth <= MOBILE_VIEWPORT_WIDTH;
        if (isMobile) {
            history.push(RoutePaths.HOME);
        }
    }, [windowWidth]);

    return (
        <BaseDesktopPage page={"forecasting"}>
            <ForecastingDashboardDesktop />
        </BaseDesktopPage>
    );
}
