import React, {
    ReactElement,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Context } from "../../State/store";
import Charts from "./ApexChartHandler";
import { Spin } from "antd";
import { getCumulativeTransactionQuery } from "../../Api/Cube/queries";
import { getCumulativeTransactionsLineGraphOptions } from "../../Utils/utils";
import {
    filterByShiftIfRequired,
    filterToFifteenMinuteTimeBlocks,
    getFormattingGranularity,
    removeDataPastCurrentTime,
} from "../../Api/Cube/utils";
import { ApexChartsSeries, SeriesRow } from "../../Api/Cube/types";
import { Query } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { formatForDisplay } from "Utils/date-utils";
import { turnArrayToObjectByKeys } from "Utils/object-utils";
import { useGetSaleAndStaffDataOverTime } from "Hooks/useGetSaleAndStaffDataOverTime";
import { useGetLastCutOffTime } from "Hooks/useGetLastCutOffTime";

interface Props {
    height: number;
}

const cumSumSeriesRows = (seriesRow: SeriesRow[]) =>
    seriesRow.reduce<Array<SeriesRow>>((result, seriesRow: SeriesRow, i) => {
        const { x, value = 0 } = seriesRow;

        if (!result.length) return [{ x, value }];

        const previousValue = result[i - 1].value ?? 0;
        result.push({ x, value: previousValue + value });

        return result;
    }, []);

export default function CumulativeTransactionsLineGraph({
    height,
}: Props): ReactElement {
    const [{ filters }] = useContext(Context);
    const [options, setOptions] = useState({});
    const [series, setSeries] = useState<ApexChartsSeries>([]);
    const { selectedComparison, selectedAggregate } = filters;

    let queries: Query | Query[] = {
        ...getCumulativeTransactionQuery(filters),
        // renewQuery: fromDate === toDate,
    };

    if (filters.mode === "forecast") {
        queries = [
            {
                ...getCumulativeTransactionQuery(filters),
                // renewQuery: fromDate === toDate,
            },
            {
                ...getCumulativeTransactionQuery(filters, "forecast"),
                renewQuery: true,
            },
        ];
    }

    const { resultSet, isLoading } = useCubeQuery(queries);
    const { isLoading: gettingCutOffTime, cutOffTime } = useGetLastCutOffTime();
    const { isLoading: gettingAverageData, data: averageData } =
        useGetSaleAndStaffDataOverTime({
            enabled: Boolean(selectedAggregate),
        });

    useEffect(() => {
        if (resultSet && !selectedAggregate) {
            const [currentSeries, comparisonSeries] = resultSet
                .series()
                .map((data) => {
                    const filteredByShiftSeries = filterByShiftIfRequired({
                        dataSeries: data.series,
                        filters,
                    });
                    const cumulativeComputedSeries =
                        cumSumSeriesRows(filteredByShiftSeries);
                    const filteredCumulativeComputedSeries =
                        filterToFifteenMinuteTimeBlocks(cumulativeComputedSeries);

                    const filteredCumulativeComputedSeriesByCurrentDate = cutOffTime
                        ? removeDataPastCurrentTime({
                              dataSeries: filteredCumulativeComputedSeries,
                              cutOffTime,
                          })
                        : filteredCumulativeComputedSeries;

                    return turnArrayToObjectByKeys<{
                        x: string[];
                        values: number[];
                    }>(filteredCumulativeComputedSeriesByCurrentDate, [
                        { inputKey: "x", resultKey: "x" },
                        { inputKey: "value", resultKey: "values" },
                    ]);
                });

            const series = [
                {
                    name: "Current Period",
                    data: currentSeries.values,
                },
            ];

            if (comparisonSeries) {
                series.push({
                    name:
                        filters.mode === "actual" ? selectedComparison! : "Forecast",
                    data: comparisonSeries.values,
                });
            }

            setOptions(
                getCumulativeTransactionsLineGraphOptions(
                    currentSeries.x,
                    getFormattingGranularity(filters)
                )
            );

            setSeries(series);
        }
    }, [resultSet]);

    useEffect(() => {
        if (selectedAggregate && averageData?.length) {
            const [averageSales] = averageData;
            const cumulativeComputedSeries = cumSumSeriesRows(averageSales.series);
            const series = turnArrayToObjectByKeys<{
                x: string[];
                values: number[];
            }>(cumulativeComputedSeries, [
                { inputKey: "x", resultKey: "x" },
                { inputKey: "value", resultKey: "values" },
            ]);
            const options = getCumulativeTransactionsLineGraphOptions(
                series.x,
                getFormattingGranularity(filters)
            );
            options.xaxis = {
                type: "datetime",
                categories: averageSales.series.map((d) => d.x),
                labels: {
                    datetimeUTC: false,
                    formatter: (val) => formatForDisplay(val, "HH:mm"),
                },
            };
            options.tooltip = {
                x: {
                    formatter: (val) => formatForDisplay(val, "HH:mm"),
                },
            };

            setOptions(options);
            setSeries([
                {
                    name: "Current Period",
                    data: series.values,
                },
            ]);
        }
    }, [averageData]);

    return (
        <div style={{ height }}>
            <Spin
                style={{ height }}
                spinning={isLoading || gettingAverageData || gettingCutOffTime}
            >
                <Charts
                    options={options}
                    series={series}
                    height={height}
                    type={"area"}
                />
            </Spin>
        </div>
    );
}
