import { BACKEND_API_URL } from "../Utils/constants";
import { getAccessToken } from "./firebase";
import moment from "moment";
import { Filters, Forecast } from "../State/store";
import { View } from "../Components/ViewSelect";
import { BaselineRawRow } from "../Components/ForecastingChartsDataManager/BaselineChart";

export interface Venue {
    primary_id: string;
    weather?: any;
}

export type Area = Venue;

export type Class = Venue;

export interface User {
    can_invite_users: boolean;
    email: string;
    group_id: string;
    name: string;
    settings: {
        defaultViewId: number;
    };
    views: View[];
    user_id: string;
    // {Depricated variables below}
    // id?: string;
    // isAdmin? : boolean;
}

export interface Segments {
    areas: Area[];
    classes: Class[];
    group_id: string;
    venues: Venue[];
}

export interface GroupData {
    cubejsToken: string;
    groupId: string;
    segments: Segments;
    user: User;
    // TODO Type this
    users: User[];
    views: { group_id?: string; views: View[] };
    forecasts: Forecast[];
}
//

export const getGroupData = (): Promise<GroupData> => {
    return getAccessToken()
        .then((access_token) => {
            if (access_token === null) {
                // Without throwing manually throwing an error the web app just crashes.
                // This is try to the request object being returned but not being handled where it has been returned to.
                throw new Error("No token");
            }

            const myHeaders = new Headers();
            myHeaders.append("Authorization", `bearer ${access_token}`);

            const requestOptions: RequestInit = {
                method: "GET",
                headers: myHeaders,
            };

            const url = `${BACKEND_API_URL}/v2/group/data`;
            return fetch(url, requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    return data;
                })
                .catch(() => {
                    return [];
                });
        })
        .catch(() => {
            window.location.href = "/something-went-wrong";
        });
};

export const addView = (viewLabel: string, filters: Filters): Promise<View> => {
    return getAccessToken().then((access_token) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `bearer ${access_token}`);

        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            view_label: viewLabel,
            date_created: moment().format("YYYY-MM-DD"),
            filters,
        });

        const requestOptions: RequestInit = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const url = `${BACKEND_API_URL}/v2/group/views`;
        return fetch(url, requestOptions).then((r) => r.json());
    });
};

export const updateView = (
    viewLabel: string,
    filters: Filters,
    viewIdToOverride?: number
): Promise<View> => {
    return getAccessToken().then((access_token) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `bearer ${access_token}`);

        myHeaders.append("Content-Type", "application/json");

        const requestBody = {
            view_label: viewLabel,
            filters,
        };

        // If a view_id is supplied to be overridden add to request body.
        if (viewIdToOverride) {
            requestBody["view_id"] = viewIdToOverride;
        }

        const raw = JSON.stringify(requestBody);

        const requestOptions: RequestInit = {
            method: "PUT",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const url = `${BACKEND_API_URL}/v2/group/views`;
        return fetch(url, requestOptions).then((r) => r.json());
    });
};

export const getViews = (): Promise<View[]> => {
    return getAccessToken().then((access_token) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `bearer ${access_token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        const url = `${BACKEND_API_URL}/v2/group/views`;
        return fetch(url, requestOptions)
            .then((r) => r.json())
            .then((data) => data.views);
    });
};

export const deleteView = (viewId: number): Promise<View> => {
    return getAccessToken().then((access_token) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `bearer ${access_token}`);

        const raw = JSON.stringify({
            view_id: viewId,
        });

        const requestOptions: RequestInit = {
            method: "DELETE",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const url = `${BACKEND_API_URL}/v2/group/views`;
        return fetch(url, requestOptions)
            .then((r) => r.json())
            .then((data) => data);
    });
};

export const getCubeJsToken = (): string | Promise<string> => {
    if (window.localStorage.getItem("cubejsToken")) {
        const generationTime = moment(
            window.localStorage.getItem("cubeJsTokenGenerationTime")
        );
        if (moment().diff(generationTime, "hours") <= 23) {
            return window.localStorage.getItem("cubejsToken")!;
        }
    }
    return getGroupData().then((groupData: GroupData) => {
        window.localStorage.setItem("cubejsToken", groupData.cubejsToken);
        window.localStorage.setItem("cubeJsTokenGenerationTime", moment().format());
        return groupData.cubejsToken;
    });
};

export const getForecasts = (): Promise<Forecast[]> => {
    return getAccessToken().then((access_token) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `bearer ${access_token}`);

        const requestOptions: RequestInit = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        const url = `${BACKEND_API_URL}/v2/group/forecasts`;

        return fetch(url, requestOptions)
            .then((r) => r.json())
            .catch(() => {
                window.location.href = "/something-went-wrong";
            });
    });
};

export const addForecast = (
    venue: string,
    weekPeriod: moment.Moment
): Promise<Forecast> => {
    return getAccessToken().then((access_token) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `bearer ${access_token}`);

        const weekStartLabel = moment(weekPeriod).format("DD/MM/YYYY");
        const weekEndLabel = moment(weekPeriod)
            .endOf("isoWeek")
            .format("DD/MM/YYYY");
        const raw = JSON.stringify({
            forecastLabel: `${venue} (${weekStartLabel} - ${weekEndLabel})`,
            configuration: {
                venue,
                weekPeriod: weekPeriod.format("YYYY-MM-DD"),
            },
            drivers: [],
            baseline: [],
            baselineConfiguration: {
                maxPercentile: 1,
                avgSmoothPreceding: 3,
                avgSmoothFollowing: 3,
            },
        });

        const requestOptions: RequestInit = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const url = `${BACKEND_API_URL}/v2/group/forecasts`;

        return fetch(url, requestOptions)
            .then((r) => r.json())
            .catch(() => {
                window.location.href = "/something-went-wrong";
            });
    });
};

export const deleteForecast = (forecastId: string): Promise<Forecast> => {
    return getAccessToken().then((access_token) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `bearer ${access_token}`);

        const raw = JSON.stringify({
            forecastId,
        });

        const requestOptions: RequestInit = {
            method: "DELETE",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const url = `${BACKEND_API_URL}/v2/group/forecasts`;

        return fetch(url, requestOptions)
            .then((r) => r.json())
            .catch(() => {
                window.location.href = "/something-went-wrong";
            });
    });
};

export const updateForecast = (forecast: Forecast): Promise<Forecast> => {
    return getAccessToken().then((access_token) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `bearer ${access_token}`);

        const raw = JSON.stringify(forecast);

        const requestOptions: RequestInit = {
            method: "PUT",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const url = `${BACKEND_API_URL}/v2/group/forecasts`;

        return fetch(url, requestOptions)
            .then((r) => r.json())
            .catch(() => {
                window.location.href = "/something-went-wrong";
            });
    });
};

export const applyForecastWithMode = (
    forecastId: string,
    forecastMode: string
): Promise<BaselineRawRow[]> => {
    return getAccessToken().then((access_token) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `bearer ${access_token}`);

        const raw = JSON.stringify({
            forecastId,
            forecastMode,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
        };

        const url = `${BACKEND_API_URL}/v2/group/forecasts/apply`;

        return fetch(url, requestOptions)
            .then((r) => r.json())
            .catch(() => {
                window.location.href = "/something-went-wrong";
            });
    });
};
