import React, {
    Dispatch,
    ReactElement,
    useContext,
    useEffect,
    useState,
} from "react";
import { Context, Filters, State } from "../../State/store";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, message, Modal, Tooltip, Typography } from "antd";
import { deleteView, getViews } from "../../Api/backend";
import {
    Action,
    ReplaceFiltersAction,
    UpdateViewsAction,
} from "../../State/actions";
import { EMPTY_FILTERS, View } from "./index";

export default function DeleteViewButton(): ReactElement {
    const [state, dispatch]: [State, Dispatch<Action>] = useContext(Context);
    const { filters }: State = state;
    const { selectedViewId }: Filters = filters;
    const { views } = state.groupData!.views;
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);

    useEffect(() => {
        setDisabled(
            views.find((view: View) => view.view_id === selectedViewId) === undefined
        );
    }, [selectedViewId]);

    const handleOk = () => {
        deleteView(selectedViewId!).then(() => {
            getViews()
                .then((views) => {
                    const action: UpdateViewsAction = {
                        type: "UPDATE_VIEWS",
                        payload: {
                            views: views,
                        },
                    };
                    dispatch(action);
                    const updateSelectedViewIdAction: ReplaceFiltersAction = {
                        type: "REPLACE_FILTERS",
                        payload: {
                            filters: {
                                ...EMPTY_FILTERS,
                                datasetName: state.filters.datasetName,
                                selectedViewId: undefined,
                            },
                        },
                    };
                    dispatch(updateSelectedViewIdAction);
                })
                .then(() => message.success("View sucessfully deleted"));
        });
        setIsModalVisible(false);
    };
    return (
        <>
            <Modal
                title="Confirm"
                visible={isModalVisible}
                onCancel={() => {
                    setIsModalVisible(false);
                }}
                footer={[
                    <Button
                        key="Cancel"
                        onClick={() => {
                            setIsModalVisible(false);
                        }}
                    >
                        Return
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Confirm
                    </Button>,
                ]}
            >
                <Typography.Text>
                    Are you sure you want to delete this view?
                </Typography.Text>
                <br />
                <Typography.Text type={"secondary"}>
                    This will also clear applied filters.
                </Typography.Text>
            </Modal>
            <Tooltip
                placement={"bottomRight"}
                title={"Delete currently selected view"}
            >
                <div className={"delete-view-button"}>
                    {" "}
                    <Button
                        disabled={!selectedViewId || disabled}
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            setIsModalVisible(true);
                        }}
                    />
                </div>
            </Tooltip>
        </>
    );
}
