import styled from "styled-components";
import React, { ReactElement } from "react";
import { Image, Affix } from "antd";
import quantacoSmallLogo from "../../quantaco-small-logo.png";
import { animated, config, useSpring } from "react-spring";
import { RoutePaths } from "../Router/Routes";

const AnimatedDiv = animated.div;

const PaddedDiv = styled(AnimatedDiv)``;

export default function ForecastingSideNavigation(): ReactElement {
    const styles = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 100,
        config: config.molasses,
    });
    return (
        <Affix>
            <PaddedDiv style={styles}>
                <a href={RoutePaths.HOME}>
                    <Image src={quantacoSmallLogo} width={"100%"} preview={false} />
                </a>
            </PaddedDiv>
        </Affix>
    );
}
