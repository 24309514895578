import React, { ReactElement } from "react";
import { Card, Col, Row, Typography } from "antd";
import styled from "styled-components";
import { WRANGLR_DARK_BLUE } from "../../Utils/constants";
import TotalSalesMetric from "./TotalSalesMetric";
import ManHrMetric from "./ManHrMetric";
import TabulatedGraphs from "./TabulatedGraphs";
import TableWidget from "./TableWidget";
import "./segmentTable.scss";

const FullHeightDiv = styled.div`
    height: 100%;
`;

export const RoundedCard = styled(Card)`
    border-radius: 15px;
    border: 1px solid #d9d9d9;
`;

const MarginBottomRow = styled(Row)`
    margin-bottom: 20px;
`;

export const WranglrDarkBlueTitle = styled(Typography.Title)`
    color: ${WRANGLR_DARK_BLUE} !important;
`;

const LoadedDashboardCards = () => (
    <>
        <MarginBottomRow gutter={20}>
            <Col span={24}>
                <Row gutter={16}>
                    <Col span={4}>
                        <Row style={{ paddingBottom: "20px" }}>
                            <RoundedCard style={{ width: "100%", height: "50%" }}>
                                <WranglrDarkBlueTitle level={4}>
                                    Sales
                                </WranglrDarkBlueTitle>
                                <TotalSalesMetric level={3} />
                            </RoundedCard>
                        </Row>
                        <Row>
                            <RoundedCard style={{ width: "100%" }}>
                                <WranglrDarkBlueTitle level={4}>
                                    /StaffHr
                                </WranglrDarkBlueTitle>
                                <ManHrMetric level={3} />
                            </RoundedCard>
                        </Row>
                    </Col>
                    <Col span={20}>
                        <RoundedCard>
                            <TabulatedGraphs height={350} />
                        </RoundedCard>
                    </Col>
                </Row>
            </Col>
        </MarginBottomRow>
        <Row>
            <Col span={24}>{<TableWidget />}</Col>
        </Row>
    </>
);

export default function HomeDashboardDesktop(): ReactElement {
    return (
        <FullHeightDiv>
            <LoadedDashboardCards />
        </FullHeightDiv>
    );
}
