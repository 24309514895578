import { Button, Checkbox, Col, Input, message, Modal, Row, Typography } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { Context, Filters, State } from "../../State/store";
import {
    UpdateViewsAction,
    ReplaceFiltersAction,
    UpdateFiltersAction,
    Action,
} from "../../State/actions";
import { EMPTY_FILTERS } from "../ViewSelect";
import { addView, getViews, updateView } from "../../Api/backend";
import { ClearOutlined, SaveOutlined } from "@ant-design/icons";
import { Dispatch } from "react";

const PaddedDiv = styled.div`
    padding: 16px;
`;

const showModal = (setIsModalVisible) => () => setIsModalVisible(true);

const handleCancel = (setIsModalVisible) => () => setIsModalVisible(false);

interface SaveViewsButtonProps {
    state: State;
    dispatch: Dispatch<Action>;
}

const SaveViewsButton = ({
    state,
    dispatch,
}: SaveViewsButtonProps): ReactElement => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [inputText, setInputText] = useState("");
    const [overrideFlag, setOverrideFlag] = useState(false);
    const [currentViewLabel, setCurrentViewLabel] = useState<string | undefined>();
    const { filters }: State = state;
    const { selectedViewId }: Filters = filters;
    const { views } = state.groupData!.views;

    const handleOk = () => {
        setIsModalVisible(false);
        const viewFilters: Filters = { ...state.filters, datasetName: undefined };

        // Depending on the override Flag invoke either a PUT (update) or POST (create) request
        const invokedRequest = overrideFlag
            ? updateView(inputText, viewFilters, selectedViewId)
            : addView(inputText, viewFilters);

        invokedRequest
            .then((res) => {
                getViews().then((views) => {
                    const action: UpdateViewsAction = {
                        type: "UPDATE_VIEWS",
                        payload: {
                            views: views,
                        },
                    };
                    dispatch(action);
                    const { view_id } = res;
                    const updateSelectedViewIdAction: UpdateFiltersAction = {
                        type: "UPDATE_FILTERS",
                        payload: {
                            filters: {
                                selectedViewId: view_id,
                            },
                        },
                    };
                    dispatch(updateSelectedViewIdAction);
                });
            })
            .then(() => {
                message.success("View Saved", 1);
            });
    };

    const onLabelChange = (e) => {
        setInputText(e.target.value);
    };

    const onCheckBoxTick = () => {
        setOverrideFlag(!overrideFlag);
    };

    // Loads the current selected view. If the selected view is non-deletable then the currentView is undefined.
    // currentView being undefined disabled the view update functionality.
    useEffect(() => {
        const CurrentView = views.find((value) => value.view_id === selectedViewId);

        if (CurrentView) {
            setCurrentViewLabel(`(${CurrentView.view_label})`);
        } else {
            setCurrentViewLabel(undefined);
        }
    }, [selectedViewId]);

    return (
        <>
            <Modal
                title="Create View"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel(setIsModalVisible)}
                afterClose={() => setOverrideFlag(false)}
            >
                <Row gutter={[8, 10]}>
                    <Col span={24}>
                        <Typography.Text>
                            Please enter view label below:
                        </Typography.Text>
                    </Col>
                    <Col span={24}>
                        <Input
                            placeholder={"label"}
                            type={"text"}
                            onChange={onLabelChange}
                        />
                    </Col>
                    {currentViewLabel && (
                        <Col span={24}>
                            <Checkbox
                                onChange={onCheckBoxTick}
                                checked={overrideFlag}
                            >
                                {`Override the currently selected view ${currentViewLabel}`}
                            </Checkbox>
                        </Col>
                    )}
                </Row>
            </Modal>
            <Button
                onClick={showModal(setIsModalVisible)}
                type={"primary"}
                block
                shape={"round"}
            >
                Save <SaveOutlined />
            </Button>
        </>
    );
};

interface ClearViewsButtonProps {
    dispatch: Dispatch<Action>;
    datasetName: string;
}
const ClearViewsButton = ({
    dispatch,
    datasetName,
}: ClearViewsButtonProps): ReactElement => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleOk = () => {
        setIsModalVisible(false);
        const action: ReplaceFiltersAction = {
            type: "REPLACE_FILTERS",
            payload: {
                filters: { ...EMPTY_FILTERS, datasetName },
            },
        };
        dispatch(action);
        message.success("Filters Cleared", 1).then();
    };

    return (
        <>
            <Modal
                title="Confirm"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel(setIsModalVisible)}
            >
                <p>Are you sure you want to clear all filters?</p>
            </Modal>
            <Button onClick={showModal(setIsModalVisible)} block shape={"round"}>
                Clear <ClearOutlined />
            </Button>
        </>
    );
};

export default function ViewsButtonGroup(): ReactElement {
    const [state, dispatch] = React.useContext(Context);
    return (
        <PaddedDiv className={"views-button-group"}>
            <Row gutter={8}>
                <Col span={12}>
                    <SaveViewsButton state={state} dispatch={dispatch} />
                </Col>
                <Col span={12}>
                    <ClearViewsButton
                        dispatch={dispatch}
                        datasetName={state.filters.datasetName!}
                    />
                </Col>
            </Row>
        </PaddedDiv>
    );
}
