import React, { ReactElement, useContext } from "react";
import { Divider, Spin, Typography } from "antd";
import styled from "styled-components";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import {
    calculatePercentageDifference,
    currencyFormatter,
    isNumber,
} from "../../Utils/utils";
import { DARK_GREEN, DARK_RED, MOBILE_VIEWPORT_WIDTH } from "../../Utils/constants";
import { CenteredStatistic } from "./components/SharedStyledComponnets";
import { Context } from "State/store";
import { Aggregate } from "Components/AggregateFilter";
import { useGetTotalSalesAndStaffHoursByDates } from "Hooks/useGetTotalSalesAndStaffHoursByDates";
import { useGetTotalSalesAndStaffHoursForComparison } from "Hooks/useGetTotalSalesAndStaffHoursForComparison";
import { useGetAverageSalesAndStaffHoursByDates } from "Hooks/useGetAverageSalesAndStaffHoursByDates";
import { useGetMedianSalesAndStaff } from "Hooks/useGetMedianSalesAndStaff";

export const Metric = styled(Typography.Title)`
    color: #6096d8 !important;
    text-align: center;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
`;

export const ReducedMarginDivider = styled(Divider)`
    margin-top: 5px;
    margin-bottom: 5px;
`;

interface Props {
    level: 1 | 2 | 3 | 4 | 5;
}

export default function TotalSalesMetric({ level }: Props): ReactElement {
    const isMobile = window.innerWidth <= MOBILE_VIEWPORT_WIDTH;
    const [
        {
            filters: { selectedAggregate },
        },
    ] = useContext(Context);

    const medianAggregateSelected =
        selectedAggregate === Aggregate.MEDIAN_DAY ||
        selectedAggregate === Aggregate.MEDIAN_WEEK;

    const { averageSalesAndStaff, isLoading: gettingAverageData } =
        useGetAverageSalesAndStaffHoursByDates();

    const { totalSalesAndStaff, isLoading: gettingData } =
        useGetTotalSalesAndStaffHoursByDates({ enabled: !medianAggregateSelected });

    const { totalSalesAndStaff: totalSalesAndStaffForComparison } =
        useGetTotalSalesAndStaffHoursForComparison();

    const { isLoading: gettingMedianData, medianSalesAndStaffInPeriods } =
        useGetMedianSalesAndStaff();

    const sales = averageSalesAndStaff
        ? averageSalesAndStaff.averageSales
        : medianSalesAndStaffInPeriods
        ? medianSalesAndStaffInPeriods.totalSales
        : totalSalesAndStaff
        ? totalSalesAndStaff.totalSales
        : undefined;

    const percentage =
        totalSalesAndStaff && totalSalesAndStaffForComparison && !selectedAggregate
            ? calculatePercentageDifference(
                  totalSalesAndStaff.totalSales,
                  totalSalesAndStaffForComparison.totalSales
              )
            : null;

    return (
        <Spin
            spinning={gettingData || gettingAverageData || gettingMedianData}
            size={"small"}
        >
            <Metric level={level}>
                {sales != undefined ? currencyFormatter(sales) : "-"}
            </Metric>
            {percentage ? (
                <>
                    {percentage && isNumber(percentage) && (
                        <>
                            <ReducedMarginDivider />
                            <CenteredStatistic
                                value={percentage}
                                precision={1}
                                valueStyle={
                                    percentage >= 0
                                        ? {
                                              color: DARK_GREEN,
                                              fontSize: isMobile ? "16px" : "22px",
                                          }
                                        : {
                                              color: DARK_RED,
                                              fontSize: isMobile ? "16px" : "22px",
                                          }
                                }
                                prefix={
                                    percentage >= 0 ? (
                                        <CaretUpFilled />
                                    ) : (
                                        <CaretDownFilled />
                                    )
                                }
                                suffix="%"
                            />
                        </>
                    )}
                </>
            ) : null}
        </Spin>
    );
}
