import { initializeApp } from "firebase/app";

export const config = {
    apiKey: process.env.REACT_APP_FIRE_BASE_KEY,
    authDomain: process.env.REACT_APP_FIRE_BASE_AUTH_DOMAIN,
    // databaseURL: process.env.REACT_APP_FIRE_BASE_DB_URL, // Not used (here for potential future reference)
    projectId: process.env.REACT_APP_FIRE_BASE_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_FIRE_BASE_STORAGE_BUCKET, // Not used (here for potential future reference)
    messagingSenderId: process.env.REACT_APP_FIRE_BASE_MESSAGING_SENDER_ID, // Production ONLY
    appId: process.env.REACT_APP_FIRE_BASE_APP_ID,
    measurementId: process.env.REACT_APP_FIRE_BASE_MEASURMENT_ID, // Production ONLY
};

// firebase.initializeApp(config);
// firebase.analytics();

const firebaseApp = initializeApp(config);

export { firebaseApp };
