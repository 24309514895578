import React, { ReactElement, useState } from "react";
import {
    Button,
    Col,
    DatePicker,
    Form,
    message,
    Modal,
    Row,
    Select,
    Skeleton,
} from "antd";
import { RoundedCard, WranglrDarkBlueTitle } from "../HomeDashboardDesktop";
import styled from "styled-components";
import { Context } from "../../State/store";
import moment from "moment";
import locale from "antd/es/date-picker/locale/en_GB";
import { useHistory } from "react-router-dom";
import { addForecast, getForecasts } from "../../Api/backend";
import { ForecastsTable } from "./ForecastsTable";
import { UpdateGroupData } from "../../State/actions";

moment.locale("en", {
    week: {
        dow: 1,
    },
});

const FullHeightRow = styled(Row)`
    height: 85vh;
`;

const RoundedButton = styled(Button)`
    border-radius: 15px;
`;

interface Props {
    loading: boolean;
}

export const CreateNewForecastButton = (): ReactElement => {
    const [modalVisible, setModalVisible] = useState(false);
    const [state, dispatch] = React.useContext(Context);
    const history = useHistory();

    const CreateNewForecastModal = () => {
        const [confirmLoading, setConfirmLoading] = useState(false);

        const [form] = Form.useForm();

        const onCancel = () => setModalVisible(false);
        const onOk = () => {
            form.validateFields().then(({ venue, weekPeriod }) => {
                setConfirmLoading(true);
                addForecast(venue, weekPeriod)
                    .then((forecast) => {
                        if (forecast.message) {
                            throw forecast.message;
                        }
                        getForecasts().then((forecasts) => {
                            const action: UpdateGroupData = {
                                type: "UPDATE_GROUP_DATA",
                                payload: {
                                    groupData: {
                                        forecasts,
                                    },
                                },
                            };
                            dispatch(action);
                            form.resetFields();
                            history.push(`/forecasting/${forecast.forecastId}`);
                        });
                    })
                    .catch((e) => {
                        if (
                            e.includes(
                                "forecast for this configuration already exists"
                            )
                        ) {
                            form.resetFields();
                            setConfirmLoading(false);
                            message
                                .error(
                                    "forecast for this configuration already exists"
                                )
                                .then();
                        }
                    });
            });
        };

        const venues = state.groupData!.segments.venues.map((venue) => ({
            id: venue.primary_id,
            value: venue.primary_id,
        }));

        return (
            <Modal
                visible={modalVisible}
                onCancel={onCancel}
                onOk={onOk}
                title={"Forecast Configuration"}
                confirmLoading={confirmLoading}
            >
                <Form form={form}>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <WranglrDarkBlueTitle level={5}>
                                Step 1: Select a Venue
                            </WranglrDarkBlueTitle>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name={"venue"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select a venue",
                                    },
                                ]}
                            >
                                <Select style={{ width: "100%" }} options={venues} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <WranglrDarkBlueTitle level={5}>
                                Step 2: Select a Week Period
                            </WranglrDarkBlueTitle>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name={"weekPeriod"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select a week period",
                                    },
                                ]}
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                    picker={"week"}
                                    defaultPickerValue={moment()
                                        .subtract(1, "week")
                                        .startOf("isoWeek")}
                                    format={(date) => {
                                        return date
                                            .startOf("isoWeek")
                                            .format("YYYY-MM-DD");
                                    }}
                                    locale={locale}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    };

    return (
        <>
            <CreateNewForecastModal />
            <RoundedButton
                type={"primary"}
                block={true}
                onClick={() => {
                    setModalVisible(true);
                }}
            >
                Create new forecast
            </RoundedButton>
        </>
    );
};

const NoForecastsCard = () => {
    return (
        <RoundedCard style={{ minHeight: 300 }} bodyStyle={{ height: "270px" }}>
            <Row justify={"center"} align={"middle"} style={{ height: "100%" }}>
                <Col>
                    <WranglrDarkBlueTitle level={4}>
                        {"You don't have any forecasts..."}
                    </WranglrDarkBlueTitle>
                </Col>
            </Row>
            <Row justify={"end"}>
                <Col span={8}>
                    <CreateNewForecastButton />
                </Col>
            </Row>
        </RoundedCard>
    );
};

export default function LandingPage({ loading }: Props): ReactElement {
    const [state] = React.useContext(Context);
    return (
        <FullHeightRow justify={"center"} align={"middle"}>
            {loading ? (
                <RoundedCard style={{ width: 850 }}>
                    <Skeleton active={true} />
                </RoundedCard>
            ) : (
                <>
                    {state.groupData!.forecasts.length === 0 ? (
                        <Col span={20} style={{ maxWidth: "700px" }}>
                            <NoForecastsCard />
                        </Col>
                    ) : (
                        <Col span={20} style={{ maxWidth: "850px" }}>
                            <ForecastsTable forecasts={state.groupData!.forecasts} />
                        </Col>
                    )}
                </>
            )}
        </FullHeightRow>
    );
}
