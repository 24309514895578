import React, { ReactElement } from "react";
import { Collapse } from "antd";
import "./tabulatedGraphs.scss";
import { SegmentTable } from "Components/SegmentTable/SegmentTable";

export default function TabulatedTables(): ReactElement {
    return (
        <div className={"tabulated-tables"}>
            <Collapse defaultActiveKey={["1"]} ghost={true}>
                <Collapse.Panel header="Venues" key="1">
                    <SegmentTable omitTitle={true} segment={"Venue"} />
                </Collapse.Panel>
                <Collapse.Panel header="Areas" key="2">
                    <SegmentTable omitTitle={true} segment={"Area"} />
                </Collapse.Panel>
                <Collapse.Panel header="Classes" key="3">
                    <SegmentTable omitTitle={true} segment={"Class"} />
                </Collapse.Panel>
            </Collapse>
        </div>
    );
}
