import React, {
    Dispatch,
    ReactElement,
    useContext,
    useEffect,
    useState,
} from "react";
import { Context, State } from "../../State/store";
import { WRANGLR_DARK_BLUE } from "../../Utils/constants";
import { getGroupData, GroupData } from "../../Api/backend";
import { Card, Col, Row, Typography } from "antd";
import styled from "styled-components";
import TotalSalesMetric from "../HomeDashboardDesktop/TotalSalesMetric";
import { WranglrDarkBlueTitle } from "../HomeDashboardDesktop";
import ManHrMetric from "../HomeDashboardDesktop/ManHrMetric";
import jwtDecode from "jwt-decode";
import TabulatedTables from "./TabulatedTables";
import TabulatedGraphsMobile from "./TabulatedGraphsMobile";
import "antd-mobile/dist/antd-mobile.css";
import ViewSelect from "../ViewSelect";
import { Action } from "../../State/actions";

const RoundedCard = styled(Card)`
    border-radius: 15px;
`;

const CenteredWranglrDarkBlueTitle = styled(WranglrDarkBlueTitle)`
    text-align: center;
`;

interface ElementTitleRowProps {
    title: string;
}

const ElementTitleRow = ({ title }: ElementTitleRowProps): ReactElement => (
    <Row style={{ marginTop: "20px" }}>
        <Col span={24}>
            <Typography.Title level={4} style={{ color: WRANGLR_DARK_BLUE }}>
                {title}
            </Typography.Title>
        </Col>
    </Row>
);

interface MetricsCardProps {
    children: ReactElement | ReactElement[];
}

const MetricsCard = ({ children }: MetricsCardProps): ReactElement => (
    <RoundedCard style={{ borderRadius: "15px" }} bodyStyle={{ padding: "5px" }}>
        {children}
    </RoundedCard>
);

export default function HomeDashboardMobile(): ReactElement {
    const [, dispatch]: [State, Dispatch<Action>] = useContext(Context);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getGroupData().then((groupData: GroupData) => {
            const datasetName = (jwtDecode(groupData.cubejsToken) as any).user
                .dataset_name;
            const payload = {
                groupData,
            };
            dispatch({ type: "ADD_GROUP_DATA", payload });
            const datasetNamePayload = {
                datasetName,
            };
            dispatch({
                type: "ADD_DATASET_NAME_TO_FILTER",
                payload: datasetNamePayload,
            });
            setLoading(false);
        });
    }, []);

    const LoadedDashboardCards = (
        <>
            {!loading && (
                <>
                    {/* Metrics Row */}
                    <Row style={{ marginBottom: "20px" }}>
                        <ViewSelect mode={"mobile"} />
                    </Row>
                    <Row gutter={8}>
                        <Col span={8}>
                            <MetricsCard>
                                <CenteredWranglrDarkBlueTitle level={5}>
                                    Sales
                                </CenteredWranglrDarkBlueTitle>
                                <TotalSalesMetric level={4} />
                            </MetricsCard>
                        </Col>
                        <Col span={8}>
                            <MetricsCard>
                                <CenteredWranglrDarkBlueTitle level={5}>
                                    /StaffHr
                                </CenteredWranglrDarkBlueTitle>
                                <ManHrMetric level={4} />
                            </MetricsCard>
                        </Col>
                        {/*<Col span={8}>*/}
                        {/*    /!*TODO remove this later, this is just a placeholder*!/*/}
                        {/*    <RoundedCard*/}
                        {/*        style={{ borderRadius: "15px", height: "100%" }}*/}
                        {/*        bodyStyle={{ padding: "10px" }}*/}
                        {/*    >*/}
                        {/*        <CenteredWranglrDarkBlueTitle*/}
                        {/*            style={{ fontSize: "12px" }}*/}
                        {/*            level={5}*/}
                        {/*        >*/}
                        {/*            Placeholder*/}
                        {/*        </CenteredWranglrDarkBlueTitle>*/}
                        {/*        <PlaceholderMetric level={4} />*/}
                        {/*    </RoundedCard>*/}
                        {/*</Col>*/}
                    </Row>
                    <ElementTitleRow title={"Visualise"} />
                    <Row>
                        <Col span={24}>
                            <MetricsCard>
                                <TabulatedGraphsMobile />
                            </MetricsCard>
                        </Col>
                    </Row>
                    <ElementTitleRow title={"Segments"} />
                    <Row>
                        <Col span={24}>
                            <MetricsCard>
                                <TabulatedTables />
                            </MetricsCard>
                        </Col>
                    </Row>
                    {/*<FixedSegmentDropdownBar />*/}
                </>
            )}
        </>
    );

    return <>{LoadedDashboardCards}</>;
}
