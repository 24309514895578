import React, { ReactElement, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import CustomLoader from "../CustomLoader";
import CubeJsProvider from "../CubeJsProvider";
import { RoutePaths } from "./Routes";
import AuthCheckRoute from "./AuthedRoute";

// Pages/components
import HomePage from "../../Pages/HomePage";
import Forecasting from "../../Pages/Forecasting";
import { QuantacoLoader } from "Components/QuantacoLoader/QuantacoLoader";
const PageNotFound = React.lazy(() => import("../../Pages/PageNotFound"));

// Lazy loaded Pages/components (see https://reactjs.org/docs/code-splitting.html for docs/info)
const UsersPage = React.lazy(() => import("../../Pages/UserManagement/Users"));
const SetPassword = React.lazy(
    () => import("../../Pages/UserManagement/SetPassword")
);
const LoginPage = React.lazy(() => import("../../Pages/UserManagement/Login"));
const SomethingWentWrongPage = React.lazy(
    () => import("../../Pages/SomethingWentWrongPage")
);
const Profile = React.lazy(() => import("../../Pages/UserManagement/Profile"));

export default function Router(): ReactElement {
    return (
        <Suspense fallback={<QuantacoLoader size={200} />}>
            <CubeJsProvider>
                <Switch>
                    <AuthCheckRoute
                        exact
                        path={RoutePaths.HOME}
                        component={HomePage}
                    />
                    <AuthCheckRoute
                        exact
                        path={RoutePaths.FORECASTING_LANDING}
                        component={Forecasting}
                    />
                    <AuthCheckRoute
                        exact
                        path={RoutePaths.FORECASTING_CREATION}
                        component={Forecasting}
                    />
                    <AuthCheckRoute
                        exact
                        path={RoutePaths.USERS}
                        component={UsersPage}
                    />
                    <AuthCheckRoute
                        exact
                        path={RoutePaths.PROFILE}
                        component={Profile}
                    />

                    <Route exact path={RoutePaths.LOGIN} component={LoginPage} />
                    <Route
                        exact
                        path={RoutePaths.SET_PASSWORD}
                        component={SetPassword}
                    />

                    <Route
                        path={RoutePaths.SOMETHING_WENT_WRONG}
                        component={SomethingWentWrongPage}
                    />
                    <Route
                        path={RoutePaths.PAGE_NOT_FOUND}
                        component={PageNotFound}
                    />

                    <Route path="*" component={PageNotFound} />
                </Switch>
            </CubeJsProvider>
        </Suspense>
    );
}
