import React, { ReactElement, useEffect, useState } from "react";
import { RoundedCard } from "../HomeDashboardDesktop";
import { Col, PageHeader, Row, Skeleton, Spin, Tabs } from "antd";
import DriversCard from "../DriversCard";
import { useHistory } from "react-router-dom";
import useCurrentForecast from "../../Hooks/useCurrentForecast";
import { Context } from "../../State/store";
import {
    ToggleApplyDriverForecasts,
    ToggleCreateForecastBaseline,
} from "../../State/actions";
import CustomLoader from "../CustomLoader";
import ForecastingChartsDataManager from "../ForecastingChartsDataManager";
import DevelopmentDebugPanel from "./DevelopmentDebugPanel";
import { ENV } from "../../Utils/constants";
import WeekPeriodSelectorManager from "./WeekPeriodSelectorManager";
import { RoutePaths } from "../Router/Routes";
import InfoTooltip from "../InfoTooltip";
import { BASELINE_CARD_TOOLTIP_TEXT, DRIVERS_CARD_TOOLTIP_TEXT } from "./constants";
interface Props {
    loading: boolean;
}

export default function ForecastCreation({ loading }: Props): ReactElement {
    if (loading) {
        return (
            <Spin indicator={<CustomLoader />}>
                <Row gutter={[8, 20]}>
                    <Col span={24}>
                        <RoundedCard>
                            <Skeleton active={true} />
                        </RoundedCard>
                    </Col>
                    <Col span={24}>
                        <RoundedCard>
                            <Skeleton active={true} />
                        </RoundedCard>
                    </Col>
                    <Col span={24}>
                        <RoundedCard>
                            <Skeleton active={true} />
                        </RoundedCard>
                    </Col>
                    <Col span={24}>
                        <RoundedCard>
                            <Skeleton active={true} />
                        </RoundedCard>
                    </Col>
                </Row>
            </Spin>
        );
    }

    const history = useHistory();
    const [, dispatch] = React.useContext(Context);
    const { forecast } = useCurrentForecast();

    //Apply chart on initial load if applicable
    useEffect(() => {
        // const venue = state.groupData?.segments.venues.find(
        //     (v) => v.primary_id === forecast.configuration.venue
        // );
        if (forecast.baseline.length > 0) {
            const action: ToggleApplyDriverForecasts | ToggleCreateForecastBaseline =
                {
                    type:
                        forecast.drivers.length > 0
                            ? "TOGGLE_APPLY_DRIVER_FORECASTS"
                            : "TOGGLE_CREATE_FORECAST_BASELINE",
                };
            dispatch(action);
        }
    }, []);

    console.log("forecast", forecast);

    return (
        <>
            <PageHeader
                onBack={() => history.push(RoutePaths.FORECASTING_LANDING)}
                title={forecast.forecastLabel}
            />
            <Row gutter={[8, 20]}>
                <Col span={24}>
                    <RoundedCard>
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane
                                tab={
                                    <InfoTooltip
                                        tooltipText={BASELINE_CARD_TOOLTIP_TEXT}
                                    >
                                        Create Sales Forecast
                                    </InfoTooltip>
                                }
                                key="1"
                            >
                                <WeekPeriodSelectorManager />
                            </Tabs.TabPane>

                            <Tabs.TabPane
                                tab={
                                    <InfoTooltip
                                        tooltipText={DRIVERS_CARD_TOOLTIP_TEXT}
                                    >
                                        Adjust Sales Forecast
                                    </InfoTooltip>
                                }
                                key="2"
                            >
                                <DriversCard />
                            </Tabs.TabPane>
                        </Tabs>
                    </RoundedCard>
                </Col>
                <ForecastingChartsDataManager />
                {(ENV === "dev" || process.env.NODE_ENV === "development") && (
                    <Col span={24}>
                        <DevelopmentDebugPanel />
                    </Col>
                )}
            </Row>
        </>
    );
}
