import { Context, State } from "../../State/store";
import React, { Dispatch, ReactElement, SetStateAction } from "react";
import CheckboxWithDrawers from "./index";
import { Action, AddVenuesToFilterAction } from "../../State/actions";
import { drawerType } from "../SideNavigation/CheckboxManager";
interface Props {
    openDrawerState: drawerType;
    setOpenDrawerState: Dispatch<SetStateAction<drawerType>>;
}

export default function VenuesCheckbox({
    openDrawerState,
    setOpenDrawerState,
}: Props): ReactElement {
    const [state, dispatch]: [State, Dispatch<Action>] = React.useContext(Context);

    const venues = state.groupData!.segments.venues;
    const selectedVenues = state.filters.selectedVenues.map(
        (venue) => venue.primary_id
    );

    const dispatchAction = (selectedVenues: string[]) => {
        const action: AddVenuesToFilterAction = {
            type: "ADD_VENUES_TO_FILTER",
            payload: {
                selectedVenues: selectedVenues.map((venue) => ({
                    primary_id: venue,
                })),
            },
        };
        dispatch(action);
    };

    return (
        <>
            <CheckboxWithDrawers
                options={venues.map((venue) => venue.primary_id).sort()}
                selectedOptions={selectedVenues}
                dispatchAction={dispatchAction}
                openDrawerState={openDrawerState}
                setOpenDrawerState={setOpenDrawerState}
                drawerType={"Venues"}
                title={"Venues"}
            />
        </>
    );
}
