import React, { ReactElement, useContext } from "react";
import { Context, State } from "../../State/store";
import { Spin, Typography } from "antd";
import { Metric, ReducedMarginDivider } from "./TotalSalesMetric";
import { calculatePercentageDifference, currencyFormatter } from "../../Utils/utils";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import {
    DARK_GREEN,
    DARK_RED,
    MOBILE_VIEWPORT_WIDTH,
    WRANGLR_DARK_BLUE,
} from "../../Utils/constants";
import styled from "styled-components";
import { CenteredStatistic } from "./components/SharedStyledComponnets";
import { Aggregate } from "Components/AggregateFilter";
import { useGetMedianSalesAndStaff } from "Hooks/useGetMedianSalesAndStaff";
import { useGetTotalSalesAndStaffHoursByDates } from "Hooks/useGetTotalSalesAndStaffHoursByDates";
import { useGetTotalSalesAndStaffHoursForComparison } from "Hooks/useGetTotalSalesAndStaffHoursForComparison";
import { ComparisonEnums } from "Components/ComparisonFilter";

interface Props {
    level: 1 | 2 | 3 | 4 | 5;
}

export const WranglrDarkBlueTitle = styled(Typography.Title)`
    color: ${WRANGLR_DARK_BLUE} !important;
`;

export default function ManHrMetric({ level }: Props): ReactElement {
    const [state] = useContext(Context);
    const {
        filters: { selectedAggregate, selectedComparison },
    }: State = state;
    const isMobile = window.innerWidth <= MOBILE_VIEWPORT_WIDTH;

    const medianAggregateSelected =
        selectedAggregate === Aggregate.MEDIAN_DAY ||
        selectedAggregate === Aggregate.MEDIAN_WEEK;

    const { totalSalesAndStaff, isLoading: gettingData } =
        useGetTotalSalesAndStaffHoursByDates({ enabled: !medianAggregateSelected });

    const { totalSalesAndStaff: totalSalesAndStaffForComparison } =
        useGetTotalSalesAndStaffHoursForComparison();

    const { isLoading: gettingMedianData, medianSalesAndStaffInPeriods } =
        useGetMedianSalesAndStaff();

    const staffEfficiency = totalSalesAndStaff
        ? totalSalesAndStaff.totalSales / totalSalesAndStaff.totalStaff
        : medianSalesAndStaffInPeriods
        ? medianSalesAndStaffInPeriods.totalSales /
          medianSalesAndStaffInPeriods.totalStaff
        : undefined;

    const staffEfficiencyForComparison =
        totalSalesAndStaffForComparison && totalSalesAndStaffForComparison
            ? totalSalesAndStaffForComparison.totalSales /
              totalSalesAndStaffForComparison.totalStaff
            : undefined;

    const percentageDifference =
        staffEfficiency &&
        staffEfficiencyForComparison &&
        !selectedAggregate &&
        selectedComparison !== ComparisonEnums.ALL_FORECAST
            ? calculatePercentageDifference(
                  staffEfficiency,
                  staffEfficiencyForComparison,
                  true
              )
            : undefined;

    return (
        <Spin spinning={gettingData || gettingMedianData} size="small">
            <Metric level={level}>
                {staffEfficiency != undefined
                    ? isFinite(staffEfficiency)
                        ? currencyFormatter(staffEfficiency, 0)
                        : "$0"
                    : "-"}
            </Metric>

            {percentageDifference != undefined && percentageDifference !== 0 && (
                <>
                    <ReducedMarginDivider />
                    <CenteredStatistic
                        value={percentageDifference}
                        precision={0}
                        valueStyle={
                            percentageDifference >= 0
                                ? {
                                      color: DARK_GREEN,
                                      fontSize: isMobile ? "16px" : "22px",
                                  }
                                : {
                                      color: DARK_RED,
                                      fontSize: isMobile ? "16px" : "22px",
                                  }
                        }
                        prefix={
                            percentageDifference >= 0 ? (
                                <CaretUpFilled />
                            ) : (
                                <CaretDownFilled />
                            )
                        }
                        suffix="%"
                    />
                </>
            )}
        </Spin>
    );
}
