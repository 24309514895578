import React, { ReactElement, useState } from "react";
import useCurrentForecast from "../../Hooks/useCurrentForecast";
import { Button, Col, Input, Row, Spin, Typography } from "antd";
import { RoundedCard } from "../HomeDashboardDesktop";
import { Forecast } from "../../State/store";
import { updateForecast } from "../../Api/backend";

const DevelopmentDebugPanel = (): ReactElement => {
    const { forecast, setCurrentForecast } = useCurrentForecast();
    const baselineConfig = forecast.baselineConfiguration;
    const [maxPercentile, setMaxPercentile] = useState(
        baselineConfig ? baselineConfig.maxPercentile : 1.0
    );
    const [avgSmoothPreceding, setAvgSmoothPreceding] = useState(
        baselineConfig ? baselineConfig.avgSmoothPreceding : 1
    );
    const [avgSmoothFollowing, setAvgSmoothFollowing] = useState(
        baselineConfig ? baselineConfig.avgSmoothFollowing : 1
    );
    const [loading, setLoading] = useState(false);

    const onClick = () => {
        setLoading(true);
        const newForecast: Forecast = {
            ...forecast,
            baselineConfiguration: {
                maxPercentile,
                avgSmoothPreceding,
                avgSmoothFollowing,
            },
        };
        updateForecast(newForecast).then((forecast: Forecast) => {
            setCurrentForecast(forecast);
            setLoading(false);
        });
    };

    return (
        <RoundedCard>
            <Spin spinning={loading}>
                <Row>
                    <Col span={8}>
                        <Typography.Text>Max Percentile</Typography.Text>
                        <Input
                            value={maxPercentile}
                            placeholder={"Max Percentile"}
                            type={"number"}
                            onChange={(val) =>
                                setMaxPercentile(val.target.valueAsNumber)
                            }
                        />
                    </Col>
                    <Col span={8}>
                        <Typography.Text>Avg Smooth Preceding</Typography.Text>
                        <Input
                            value={avgSmoothPreceding}
                            placeholder={"Avg Smooth Preceding"}
                            type={"number"}
                            onChange={(val) =>
                                setAvgSmoothPreceding(val.target.valueAsNumber)
                            }
                        />
                    </Col>
                    <Col span={8}>
                        <Typography.Text>Avg Smooth Following</Typography.Text>
                        <Input
                            value={avgSmoothFollowing}
                            placeholder={"Avg Smooth Following"}
                            type={"number"}
                            onChange={(val) =>
                                setAvgSmoothFollowing(val.target.valueAsNumber)
                            }
                        />
                    </Col>
                    <Col span={24}>
                        <Button type={"primary"} loading={loading} onClick={onClick}>
                            Apply
                        </Button>
                    </Col>
                </Row>
            </Spin>
        </RoundedCard>
    );
};

export default DevelopmentDebugPanel;
