import React, { Dispatch, ReactElement, useState } from "react";
import { Button, Col, Image, Menu, Row, Tag, Typography } from "antd";
import styled from "styled-components";
import { Header } from "antd/es/layout/layout";
import { NavLink as Link } from "react-router-dom";
import UserAvatarDropdown from "../UserAvatarDropdown";
import { Context, State } from "../../State/store";
import { FORECASTING_TAB_ENABLED } from "../../Utils/constants";
import { Action } from "../../State/actions";
import { QuestionCircleOutlined } from "@ant-design/icons";
import salesLineLogo from "salesline-logo.svg";

const FlexCol = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FlexLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const WhiteHeader = styled(Header)`
    background: #fff;
    padding-left: 0;
    padding-right: 0;
    height: 65px;
`;

const UserAvatarCol = styled(FlexCol)`
    margin-right: 22px;
`;

interface Props {
    mode: "desktop" | "mobile";
    page?: string;
}

function TopNavigationMobile(): ReactElement {
    const [state]: [State, Dispatch<Action>] = React.useContext(Context);
    const { groupData } = state;

    return (
        <>
            {groupData && (
                <WhiteHeader>
                    <Row
                        style={{ borderBottom: "1px solid #d9d9d9", height: "65px" }}
                        justify={"center"}
                    >
                        <FlexCol flex={"200px"}>
                            <FlexLink to={"/"}>
                                <Image
                                    src={salesLineLogo}
                                    preview={false}
                                    width="200px"
                                    style={{ marginLeft: 30 }}
                                />
                            </FlexLink>
                        </FlexCol>
                    </Row>
                </WhiteHeader>
            )}
        </>
    );
}

interface TopNavigationDesktopProps {
    page?: string;
}

function TopNavigationDesktop({ page }: TopNavigationDesktopProps): ReactElement {
    const [current, setCurrent] = useState(page!);

    const handleClick = (e) => {
        setCurrent(e.key);
    };

    return (
        <WhiteHeader>
            <Row style={{ borderBottom: "1px solid #d9d9d9" }}>
                <Col flex={"auto"}>
                    <Menu
                        onClick={handleClick}
                        selectedKeys={[current]}
                        mode="horizontal"
                    >
                        <Menu.Item key="home">
                            <Link to={"/"}>Home</Link>
                        </Menu.Item>
                        {FORECASTING_TAB_ENABLED === "true" && (
                            <Menu.Item key="forecasting">
                                <Link to={"/forecasting"}>
                                    <Typography.Text>Forecasting</Typography.Text>{" "}
                                    <Tag>Beta</Tag>
                                </Link>
                            </Menu.Item>
                        )}
                    </Menu>
                </Col>
                <UserAvatarCol>
                    <Button
                        style={{
                            border: "none",
                            marginRight: -15,
                        }}
                        href="https://quantaco.notion.site/Salesline-Help-3bff29d10ea3493f885219a695520b27"
                        target="_blank"
                        rel="noreferrer"
                        icon={
                            <QuestionCircleOutlined
                                style={{
                                    fontSize: "25px",
                                    backgroundColor: "#f0f0f0",
                                    borderRadius: "50%",
                                    marginTop: 5,
                                }}
                            />
                        }
                    />
                    <UserAvatarDropdown />
                </UserAvatarCol>
            </Row>
        </WhiteHeader>
    );
}

export default function TopNavigation({ mode, page }: Props): ReactElement {
    if (mode === "desktop") {
        return <TopNavigationDesktop page={page!} />;
    } else {
        return <TopNavigationMobile />;
    }
}
