// Turn an array of objects into object of array
// Example: [ {prop1: 2}, {prop1: 3}, {prop1: 4}]
// will turn to { props1: [2,3,4] }
export const turnArrayToObjectByKeys = <T>(
    arr: any[],
    keys: Array<{ inputKey: any; resultKey: any }>
): T =>
    arr.reduce<T>((result, nextValue, i) => {
        keys.forEach(({ resultKey, inputKey }) => {
            if (i === 0) {
                result[resultKey] = [nextValue[inputKey]];
            } else {
                result[resultKey].push(nextValue[inputKey]);
            }
        });
        return result;
    }, {} as T);
