import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Context, Filters, State } from "../../State/store";
import styled from "styled-components";
import { Select } from "antd";
import { getDateGranularity } from "../../Api/Cube/utils";
import { SelectOption } from "../DriversCard/DriverModal";
import { UpdateFiltersAction } from "../../State/actions";
import { debounce } from "lodash";
import { client } from "index";
import { cubejsApi } from "Components/CubeJsProvider";
import { prefetchTotalSalesAndStaffHoursForComparison } from "Hooks/useGetTotalSalesAndStaffHoursForComparison";

export enum ComparisonEnums {
    PREVIOUS_PERIOD = "Previous Period",
    SAME_PERIOD_LAST_YEAR = "Same Period Last Year",
    SAME_DAY_LAST_WEEK = "Same Day Last Week",
    ALL_FORECAST = "Forecast",
}

const StyledSelect = styled(Select)`
    width: 100%;
`;

export default function ComparisonFilter(): ReactElement {
    const [options, setOptions] = useState<SelectOption[]>([]);
    const [displayValue, setDisplayValue] = useState<null | string>("");
    const [state, dispatch] = useContext(Context);
    const { filters }: State = state;
    const { selectedAggregate, selectedComparison, selectedDates, mode }: Filters =
        filters;

    useEffect(() => {
        setOptions(
            Object.keys(ComparisonEnums).map((comparison: string) => ({
                key: ComparisonEnums[comparison],
                value: ComparisonEnums[comparison],
            }))
        );
    }, []);

    useEffect(() => {
        const comparisonOptions = Object.keys(ComparisonEnums);
        if (getDateGranularity(filters) !== "minute") {
            if (selectedComparison === ComparisonEnums.SAME_DAY_LAST_WEEK) {
                const postsData = {
                    selectedComparison: null,
                };

                dispatch({ type: "ADD_COMPARISON_TO_FILTER", payload: postsData });
            }

            setOptions((options) =>
                options.filter(
                    (option: any) =>
                        option.value !== ComparisonEnums.SAME_DAY_LAST_WEEK
                )
            );
        } else if (options.length !== comparisonOptions.length) {
            // Rebuild comparisons options if a day is selected and the day comparison option has been removed
            setOptions(
                comparisonOptions.map((comparison: string) => ({
                    key: ComparisonEnums[comparison],
                    value: ComparisonEnums[comparison],
                }))
            );
        }
    }, [selectedDates]);

    useEffect(() => {
        setDisplayValue(
            mode === "forecast" &&
                selectedComparison !== ComparisonEnums.ALL_FORECAST
                ? "Forecast"
                : selectedComparison
        );
    }, [mode, selectedComparison]);

    const onChange = (selectedComparison) => {
        const updateFiltersAction: UpdateFiltersAction = {
            type: "UPDATE_FILTERS",
            payload: {
                filters: {
                    ...state.filters,
                    selectedComparison: selectedComparison as ComparisonEnums,
                    // Modify the mode of filtering to be 'forecast' when All Forecast is selected. Otherwise, the behaviour is as normal.
                    mode:
                        selectedComparison === ComparisonEnums.ALL_FORECAST
                            ? "forecast"
                            : "actual",
                } as Filters,
            },
        };
        dispatch(updateFiltersAction);
    };

    const onClear = () => {
        if (mode === "forecast") {
            const updateFiltersAction: UpdateFiltersAction = {
                type: "UPDATE_FILTERS",
                payload: {
                    filters: {
                        //When comparisons are cleared, we want to ensure that the mode is reset back to 'actual' in case a forecast mode is selected
                        ...state.filters,
                        mode: "actual",
                    } as Filters,
                },
            };
            dispatch(updateFiltersAction);
        }
    };

    const handleMouseComparisonOption = debounce(
        (comparison: ComparisonEnums) => {
            prefetchTotalSalesAndStaffHoursForComparison({
                ...filters,
                selectedComparison: comparison,
            });
        },
        500,
        { leading: true }
    );

    return (
        <StyledSelect
            disabled={!!selectedAggregate}
            placeholder="Select Comparison Period"
            showArrow={true}
            onChange={onChange}
            allowClear={true}
            onClear={onClear}
            defaultValue={ComparisonEnums.SAME_DAY_LAST_WEEK}
            value={displayValue as string}
        >
            {options.map(({ key, value }) => (
                <Select.Option
                    onMouseEnter={() =>
                        handleMouseComparisonOption(value as ComparisonEnums)
                    }
                    key={key}
                    value={value}
                >
                    {value}
                </Select.Option>
            ))}
        </StyledSelect>
    );
}
