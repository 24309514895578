import { Filters } from "../../State/store";
import { getFilterSet, getMeasures, getOrdering, getTimeDimension } from "./utils";
import { Query } from "@cubejs-client/core";
import { SalesType } from "./types";
import { DEFAULT_TIMEZONE } from "../../Utils/constants";
export const getCumulativeTransactionQuery = (
    filters: Filters,
    salesType: SalesType = "actual"
): Query => {
    return {
        measures: getMeasures(filters, false, false, salesType)!,
        timeDimensions: getTimeDimension(filters),
        order: getOrdering(filters),
        filters: getFilterSet(filters),
        timezone: DEFAULT_TIMEZONE,
    };
};
