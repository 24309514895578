import { SeriesRow } from "Api/Cube/types";
import {
    CubeQueryBuilder,
    filterByShiftIfRequired,
    filterToFifteenMinuteTimeBlocks,
    getAllDatesByWeekDayInRange,
    getFilterSet,
    getMeasures,
    getOrdering,
    getTimeDimension,
    getTimeFilter,
    sumByDayIfRequired,
    todayIncludedInSelectedDateRange,
} from "Api/Cube/utils";
import { Aggregate } from "Components/AggregateFilter";
import { cubejsApi } from "Components/CubeJsProvider";
import { useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { Context } from "State/store";
import { Nullable } from "types";
import {
    calculateNumberOfDaysAndWeeksSelectedInRange,
    DATE_TIME_FORMAT,
    parseToMoment,
    SECONDS_IN_MINUTE,
} from "Utils/date-utils";
import { useGetMedianSalesDates } from "./useGetMedianSalesDates";

export const SALE_AND_STAFF_DATA_OVERTIME_QUERY_KEY = "SaleAndStaffOverTime";

export const useGetSaleAndStaffDataOverTime = (
    params: { enabled?: boolean; excludeStaffData?: boolean } = {}
) => {
    const { enabled = true, excludeStaffData = false } = params;
    const [{ filters }] = useContext(Context);

    const {
        selectedAggregate,
        selectedDates: { fromDate: start, toDate: end },
        datasetName,
    } = filters;

    const averageAggregateSelected =
        selectedAggregate === Aggregate.AVERAGE_WEEK ||
        selectedAggregate === Aggregate.AVERAGE_DAY;

    const medianAggregateSelected =
        selectedAggregate === Aggregate.MEDIAN_DAY ||
        selectedAggregate === Aggregate.MEDIAN_WEEK;

    const { isLoading: gettingDateWithMedianSales, periodsWithMedianSales } =
        useGetMedianSalesDates();

    const filtersWithMedianSalesDate =
        medianAggregateSelected && periodsWithMedianSales
            ? {
                  ...filters,
                  selectedDates: {
                      fromDate: periodsWithMedianSales.start,
                      toDate: periodsWithMedianSales.end,
                  },
              }
            : undefined;

    const query = CubeQueryBuilder()
        .addMeasures(getMeasures(filters, !excludeStaffData))
        .addOrder(getOrdering(filters))
        .addFilters(getFilterSet(filters, true))
        // Only add time filter if query for average day / week
        .addFilters(
            [getTimeFilter({ start, end, datasetName: datasetName! })],
            averageAggregateSelected
        )
        .addTimeDimensions(
            filtersWithMedianSalesDate
                ? getTimeDimension(filtersWithMedianSalesDate, true)
                : getTimeDimension(filters, true)
        )
        .getResult({ ignoreTimeZone: averageAggregateSelected });

    const calculateAverageDayOrWeek = (series: SeriesRow[]) => {
        const { numberOfWeeksSelected, numberOfDaysSelected } =
            calculateNumberOfDaysAndWeeksSelectedInRange(start, end);

        let numberOfWeekDaysInPeriods: Nullable<number> = null;
        if (filters.selectedDays.length) {
            const datesByWeekDay = getAllDatesByWeekDayInRange(start, end);

            numberOfWeekDaysInPeriods = filters.selectedDays.reduce(
                (result, day) => result + datesByWeekDay[day].length,
                0
            );
        }

        if (selectedAggregate === Aggregate.AVERAGE_WEEK) {
            const averageSeries = series
                .filter(({ x }) => x >= "1970-01-01T06:00:00.000")
                .map(({ x, value = 0 }) => {
                    return {
                        value:
                            numberOfWeeksSelected === 0
                                ? value
                                : value /
                                  (numberOfWeekDaysInPeriods ??
                                      numberOfWeeksSelected),
                        x: parseToMoment(x)
                            .set({
                                // Choose 2018 here as the 1 -> 7 / 01/2018 is Mon -> Sun
                                year: 2018,
                            })
                            .format(DATE_TIME_FORMAT),
                    };
                });
            return averageSeries.map(({ value, ...otherProps }, i) => {
                const valueBefore = averageSeries[i - 1]?.value ?? 0;
                const valueAfter = averageSeries[i + 1]?.value ?? 0;
                const window = valueBefore && valueAfter ? 3 : 2;
                return {
                    ...otherProps,
                    value: (valueBefore + valueAfter + value) / window,
                };
            });
        }

        const fifteenMinutesSeries = filterToFifteenMinuteTimeBlocks(series);
        const averageSeries = fifteenMinutesSeries
            .map(({ x, value = 0 }) => {
                const parsedMomentX = parseToMoment(x);
                const currentYear = parseToMoment().get("year");
                const window = numberOfWeekDaysInPeriods ?? numberOfDaysSelected;

                parsedMomentX.set({ year: currentYear });
                if (parsedMomentX.get("hours") < 6) {
                    parsedMomentX.add(1, "day");
                }
                return {
                    value: window === 0 ? value : value / window,
                    x: parsedMomentX.format(DATE_TIME_FORMAT),
                };
            })
            .sort((a, b) => (a.x > b.x ? 1 : -1));
        return averageSeries.map(({ value, ...otherProps }, i) => {
            const valueBefore = averageSeries[i - 1]?.value ?? 0;
            const valueAfter = averageSeries[i + 1]?.value ?? 0;
            const window = valueBefore && valueAfter ? 3 : 2;
            return {
                ...otherProps,
                value: (valueBefore + valueAfter + value) / window,
            };
        });
    };

    const getSaleAndStaffDataOvertimeResult = useQuery(
        [SALE_AND_STAFF_DATA_OVERTIME_QUERY_KEY, query],
        () => cubejsApi.load(query),
        {
            enabled,
            staleTime: todayIncludedInSelectedDateRange(filters)
                ? 0
                : SECONDS_IN_MINUTE * 15 * 1000,
        }
    );

    const data = useMemo(() => {
        if (!getSaleAndStaffDataOvertimeResult.data) return;

        return getSaleAndStaffDataOvertimeResult.data
            .series({ fillMissingDates: !todayIncludedInSelectedDateRange(filters) })
            .map(({ series, ...others }) => {
                const processedSeries = averageAggregateSelected
                    ? calculateAverageDayOrWeek(series)
                    : sumByDayIfRequired(
                          filterByShiftIfRequired({
                              dataSeries: filterToFifteenMinuteTimeBlocks(series),
                              filters: filtersWithMedianSalesDate
                                  ? filtersWithMedianSalesDate
                                  : filters,
                          }),
                          filtersWithMedianSalesDate
                              ? filtersWithMedianSalesDate
                              : filters
                      );
                return {
                    ...others,
                    series: processedSeries,
                };
            });
    }, [getSaleAndStaffDataOvertimeResult.data]);

    return {
        isLoading:
            getSaleAndStaffDataOvertimeResult.isLoading ||
            gettingDateWithMedianSales,
        data,
    };
};
