import { Segments } from "Api/backend";
import { getAllDatesByWeekDayInRange } from "Api/Cube/utils";
import { Aggregate } from "Components/AggregateFilter";
import { cloneDeep } from "lodash";
import { useContext, useMemo } from "react";
import { Context } from "State/store";
import { Nullable } from "types";
import { calculateNumberOfDaysAndWeeksSelectedInRange } from "Utils/date-utils";
import {
    GetSaleAndStaffDataByDatesParams,
    useGetTotalSalesAndStaffHoursByDates,
} from "./useGetTotalSalesAndStaffHoursByDates";

type AverageSalesAndStaffDataBySegment = {
    [name: string]: {
        name: string;
        averageSales: number;
        averageStaff: number;
    };
};

const getInitialAverageSaleAndStaffDataBySegment = ({
    areas,
    venues,
    classes,
}: Segments) => ({
    Area: areas.reduce<AverageSalesAndStaffDataBySegment>(
        (result, { primary_id }) => {
            result[primary_id] = {
                name: primary_id,
                averageSales: 0,
                averageStaff: 0,
            };
            return result;
        },
        {}
    ),
    Venue: venues.reduce<AverageSalesAndStaffDataBySegment>(
        (result, { primary_id }) => {
            result[primary_id] = {
                name: primary_id,
                averageSales: 0,
                averageStaff: 0,
            };
            return result;
        },
        {}
    ),
    Class: classes.reduce<AverageSalesAndStaffDataBySegment>(
        (result, { primary_id }) => {
            result[primary_id] = {
                name: primary_id,
                averageSales: 0,
                averageStaff: 0,
            };
            return result;
        },
        {}
    ),
});

export interface GetAverageSalesAndStaffHoursByDatesResponse {
    isLoading?: boolean;
    averageSalesAndStaff?: {
        averageSales: number;
        averageStaff: number;
    };
    averageSalesAndStaffBySegment?: {
        Area: AverageSalesAndStaffDataBySegment;
        Venue: AverageSalesAndStaffDataBySegment;
        Class: AverageSalesAndStaffDataBySegment;
    };
}

export const useGetAverageSalesAndStaffHoursByDates = (
    params: GetSaleAndStaffDataByDatesParams = {}
): GetAverageSalesAndStaffHoursByDatesResponse => {
    const [{ filters, groupData }] = useContext(Context);
    if (!groupData) {
        throw new Error("No Group Data, this should be impossible");
    }
    const { breakdownBySegment } = params;
    const { selectedAggregate, selectedDates } = filters;

    const { fromDate: start, toDate: end } = selectedDates;

    let numberOfWeekDaysInPeriods: Nullable<number> = null;
    if (filters.selectedDays.length) {
        const datesByWeekDay = getAllDatesByWeekDayInRange(start, end);

        numberOfWeekDaysInPeriods = filters.selectedDays.reduce(
            (result, day) => result + datesByWeekDay[day].length,
            0
        );
    }

    const initialAverageSalesAndStaffBySegment = useMemo(
        () => getInitialAverageSaleAndStaffDataBySegment(groupData.segments),
        []
    );

    const numberOfDaysAndWeeksSelected = useMemo(
        () =>
            calculateNumberOfDaysAndWeeksSelectedInRange(
                selectedDates.fromDate,
                selectedDates.toDate
            ),
        [selectedDates]
    );

    const { totalSalesAndStaff, totalSalesAndStaffBySegment, isLoading } =
        useGetTotalSalesAndStaffHoursByDates({
            ...params,
            enabled:
                selectedAggregate === Aggregate.AVERAGE_DAY ||
                selectedAggregate === Aggregate.AVERAGE_WEEK,
        });

    const averageSalesAndStaffBySegment = useMemo(() => {
        if (!breakdownBySegment || !totalSalesAndStaffBySegment) {
            return undefined;
        }

        const { selectedAggregate, selectedDays } = filters;
        const { numberOfDaysSelected, numberOfWeeksSelected } =
            numberOfDaysAndWeeksSelected;

        const averageSalesAndStaff = cloneDeep(initialAverageSalesAndStaffBySegment);
        Object.keys(totalSalesAndStaffBySegment).forEach((segment) => {
            Object.values(totalSalesAndStaffBySegment[segment]).forEach(
                ({ name, totalSales, totalStaff }: any) => {
                    averageSalesAndStaff[segment][name].averageSales =
                        selectedAggregate === Aggregate.AVERAGE_DAY
                            ? totalSales /
                              (numberOfWeekDaysInPeriods ?? numberOfDaysSelected)
                            : numberOfWeeksSelected === 0
                            ? totalSales
                            : totalSales / numberOfWeeksSelected;

                    averageSalesAndStaff[segment][name].averageStaff =
                        selectedAggregate === Aggregate.AVERAGE_DAY &&
                        !selectedDays.length
                            ? totalStaff /
                              (numberOfWeekDaysInPeriods ?? numberOfDaysSelected)
                            : numberOfWeeksSelected === 0
                            ? totalStaff
                            : totalStaff /
                              (numberOfWeekDaysInPeriods ?? numberOfWeeksSelected);
                }
            );
        });

        return averageSalesAndStaff;
    }, [breakdownBySegment, totalSalesAndStaffBySegment]);

    const averageSalesAndStaff = useMemo(() => {
        if (!totalSalesAndStaff) {
            return undefined;
        }
        const { totalSales, totalStaff } = totalSalesAndStaff;
        const { selectedAggregate, selectedDays } = filters;

        if (!selectedAggregate || (totalSales === 0 && totalStaff === 0)) {
            return { averageSales: 0, averageStaff: 0 };
        }

        const { numberOfDaysSelected, numberOfWeeksSelected } =
            numberOfDaysAndWeeksSelected;

        if (selectedAggregate === Aggregate.AVERAGE_DAY && !selectedDays.length) {
            return {
                averageSales:
                    totalSales / (numberOfWeekDaysInPeriods ?? numberOfDaysSelected),
                averageStaff:
                    totalStaff / (numberOfWeekDaysInPeriods ?? numberOfDaysSelected),
            };
        }

        return numberOfWeeksSelected === 0
            ? { averageSales: totalSales, averageStaff: totalStaff }
            : {
                  averageSales:
                      totalSales /
                      (numberOfWeekDaysInPeriods ?? numberOfWeeksSelected),
                  averageStaff:
                      totalStaff /
                      (numberOfWeekDaysInPeriods ?? numberOfWeeksSelected),
              };
    }, [filters, totalSalesAndStaff]);

    return { isLoading, averageSalesAndStaff, averageSalesAndStaffBySegment };
};
