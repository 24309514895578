import React, { ReactElement, useContext } from "react";
import { Context } from "../../State/store";
import styled from "styled-components";
import { Select } from "antd";

export enum Aggregate {
    AVERAGE_DAY = "Average Day",
    AVERAGE_WEEK = "Average Week",
    MEDIAN_DAY = "Median Day",
    MEDIAN_WEEK = "Median Week",
}

const supportedAggregates = [
    Aggregate.AVERAGE_DAY,
    Aggregate.AVERAGE_WEEK,
    // Aggregate.MEDIAN_DAY,
    // Aggregate.MEDIAN_WEEK,
];

const StyledSelect = styled(Select)`
    width: 100%;
`;

export default function AggregateFilter(): ReactElement {
    const [
        {
            filters: { selectedAggregate },
        },
        dispatch,
    ] = useContext(Context);

    const onChange = (selectedAggregate) => {
        const postsData = {
            selectedAggregate,
        };

        dispatch({ type: "ADD_AGGREGATE_TO_FILTER", payload: postsData });
    };

    return (
        <StyledSelect
            placeholder="Select Aggregate Period"
            showArrow={true}
            onChange={onChange}
            allowClear={true}
            value={selectedAggregate ? selectedAggregate : undefined}
        >
            {supportedAggregates.map((supportedAggregate) => (
                <Select.Option value={supportedAggregate} key={supportedAggregate}>
                    {supportedAggregate}
                </Select.Option>
            ))}
        </StyledSelect>
    );
}
