export const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

// Colours
export const WRANGLR_DARK_BLUE = "#1F497D";
export const WRANGLR_LIGHT_BLUE = "#517EB5";
export const FORECASTING_TAB_ENABLED = process.env.REACT_APP_FORECASTING_TAB_ENABLED;
export const ERROR_COLOR = "#FF4D4F";
export const WARNING_COLOR = "#FAB121";
export const DARK_GREEN = "#3f8600";
export const DARK_RED = "#cf1322";
export const BLACK = "#000000";
export const APEX_CHARTS_GRAY = "#a4a3a3";
export const APEX_CHARTS_DARK_BLUE = "#4b81e0";
export const APEX_CHARTS_LIGHT_BLUE = "#008FFB";
export const ENV: string = process.env.REACT_APP_ENV!;
export const DEFAULT_TIMEZONE = "Australia/Sydney";

export const MOBILE_VIEWPORT_WIDTH = 500;
