import React, { ReactElement } from "react";
import Store from "./State/store";
import "./App.css";
import Router from "./Components/Router";
import { BrowserRouter } from "react-router-dom";

function App(): ReactElement {
    return (
        <Store>
            <div className="App">
                <BrowserRouter>
                    <Router />
                </BrowserRouter>
            </div>
        </Store>
    );
}

export default App;
