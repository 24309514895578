import { Context, State } from "../../State/store";
import React, { Dispatch, ReactElement, SetStateAction } from "react";
import CheckboxWithDrawers from "./index";
import { Action, AddClassToFilterAction } from "../../State/actions";
import { drawerType } from "../SideNavigation/CheckboxManager";

interface Props {
    openDrawerState: drawerType;
    setOpenDrawerState: Dispatch<SetStateAction<drawerType>>;
}
export default function ClassesCheckbox({
    openDrawerState,
    setOpenDrawerState,
}: Props): ReactElement {
    const [state, dispatch]: [State, Dispatch<Action>] = React.useContext(Context);
    const classes = state.groupData!.segments.classes;
    const selectedClasses = state.filters.selectedClasses.map((c) => c.primary_id);

    const dispatchAction = (selectedClasses: string[]) => {
        const action: AddClassToFilterAction = {
            type: "ADD_CLASS_TO_FILTER",
            payload: {
                selectedClasses: selectedClasses.map((c) => ({
                    primary_id: c,
                })),
            },
        };
        dispatch(action);
    };

    return (
        <>
            <CheckboxWithDrawers
                options={classes.map((c) => c.primary_id).sort()}
                dispatchAction={dispatchAction}
                selectedOptions={selectedClasses}
                openDrawerState={openDrawerState}
                setOpenDrawerState={setOpenDrawerState}
                drawerType={"Classes"}
                title={"Classes"}
            />
        </>
    );
}
