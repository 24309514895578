import { getAuth } from "firebase/auth";
import { firebaseApp } from "../Utils/firebase";

/**  Gets the current access-token/id-token.
 *   Automatically refreshes the token in the events of expiry
 */
export const getAccessToken = async (): Promise<string | null> => {
    const auth = getAuth(firebaseApp);

    const CURRENT_USER = auth.currentUser;

    if (CURRENT_USER === null) {
        return null;
    }

    return await CURRENT_USER.getIdToken();
};
