import React, { ReactElement, useEffect, useState } from "react";
import { Select } from "antd";
import { View } from "./index";

interface Props {
    className?: string;
    views: View[];
    defaultViewId: number | null;
    onClear;
    onSelectView;
    selectedViewId: number | undefined;
}

export default function ViewSelectDesktop({
    className,
    views,
    defaultViewId,
    onClear,
    onSelectView,
    selectedViewId,
}: Props): ReactElement {
    const [options, setOptions] = useState<ReactElement[]>([]);
    const onChange = (viewId) => {
        const view: View = views.find((view) => view.view_id === viewId)!;
        if (view === undefined) {
            //apply no filters if view not detected
            return;
        }
        onSelectView(view);
    };

    useEffect(() => {
        const children: ReactElement[] = [];
        views.forEach((view) => {
            children.push(
                <Select.Option key={view.view_id} value={view.view_id}>
                    {view.view_label} {defaultViewId === view.view_id && "(default)"}
                </Select.Option>
            );
        });
        setOptions(children);
    }, [views]);

    return (
        <div className={"view-select"}>
            <Select
                placeholder={"Select View"}
                onChange={onChange}
                allowClear={true}
                onClear={onClear}
                className={className}
                value={
                    views.find((option) => option.view_id === selectedViewId)
                        ?.view_id
                }
                dropdownMatchSelectWidth={false}
            >
                {options}
            </Select>
        </div>
    );
}
