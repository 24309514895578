import moment from "moment";
import {
    APEX_CHARTS_DARK_BLUE,
    APEX_CHARTS_GRAY,
    MOBILE_VIEWPORT_WIDTH,
} from "./constants";
import { Filters } from "../State/store";
import { Granularity, SeriesRow } from "../Api/Cube/types";
import { ResultSet } from "@cubejs-client/core";

import { DAYS_OF_WEEK } from "./types";
import { ApexOptions } from "apexcharts";

/**
 * General utility functions should go here.
 */
const calculateWeekTooltipString = (val): string => {
    const curWeek = moment(val);
    const endOfWeek = moment(val).add(7, "days");
    return (
        curWeek.format("D MMM YYYY") +
        " to " +
        endOfWeek.format("D MMM YYYY") +
        " (Week " +
        curWeek.format("w") +
        ")"
    );
};

const getStrokeWidthBasedOnGranularity = (granularity: Granularity): number => {
    switch (granularity) {
        case "minute":
            return 5;
        case "day":
            return 5;
        case "hour":
            return 3;
        case "week":
            return 5;
        case "month":
            return 5;
    }
};

export const formatXAxisTimestampBasedOnGranularity = (
    granularity: Granularity,
    val: string
): string => {
    switch (granularity) {
        case "minute":
            return moment(val).format("HH:mm");
        case "hour":
            return moment(val).format("dddd HH:00");
        case "day":
            return moment(val).format("ddd, MMMM Do");

        case "week":
            return " Week " + moment(val).format("w (YYYY)");
        case "month":
            return moment(val).format("MMMM YYYY");
    }
};

const formatTooltipTimestampBasedOnGranularity = (
    granularity: Granularity,
    val: string | number
) => {
    switch (granularity) {
        case "minute":
            return moment(val).format("HH:mm");
        case "hour":
            return moment(val).format("ddd HH:mm");
        case "day":
            return moment(val).format("dddd, MMMM Do");

        case "week":
            return calculateWeekTooltipString(val);
        case "month":
            return moment(val).format("MMMM YYYY");
    }
};

export function currencyFormatter(num: number, fractionDigits = 1): string {
    return "$" + numFormatter(num, fractionDigits);
}

export const getCumulativeTransactionsLineGraphOptions = (
    xAxis: string[],
    granularity: Granularity
): ApexOptions => {
    return {
        chart: {
            id: "basic-line",
            toolbar: {
                tools: {
                    download: false,
                    pan: false,
                    zoomin: false,
                    zoomout: false,
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            type: "datetime",
            categories: xAxis,
            labels: {
                datetimeUTC: false,
                formatter: (val) => {
                    return formatXAxisTimestampBasedOnGranularity(granularity, val);
                },
            },
        },
        tooltip: {
            x: {
                formatter: (val) =>
                    formatTooltipTimestampBasedOnGranularity(granularity, val),
            },
        },
        colors: [APEX_CHARTS_DARK_BLUE, APEX_CHARTS_GRAY],
        yaxis: {
            tickAmount: 4,
            min: 0,
            labels: {
                formatter: (num) => {
                    return currencyFormatter(num);
                },
            },
        },
        grid: {
            show: true,
        },
        stroke: {
            width: window.innerWidth <= MOBILE_VIEWPORT_WIDTH ? 2 : 5,
            curve: "straight",
        },
    };
};

/**
 *
 * Formats large number into a more readable format.
 *
 * @param num
 * @param fractionDigits
 * @returns
 */
export function numFormatter(num: number, fractionDigits = 1): string {
    if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(fractionDigits) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
        return (num / 1000000).toFixed(fractionDigits) + "M"; // convert to M for number from > 1 million
    } else {
        return (
            Math.round(num * 10 ** fractionDigits) /
            10 ** fractionDigits
        ).toString(); // if value < 1000, nothing to do
    }
}

export const getSalesOverTimeLineGraphOptions = (
    xAxis: string[],
    granularity: Granularity
): ApexOptions => ({
    chart: {
        id: "basic-line",
        toolbar: {
            offsetX: -20,
            tools: {
                download: false,
                pan: false,
                zoomin: false,
                zoomout: false,
            },
        },
    },
    xaxis: {
        type: "datetime",
        categories: xAxis,
        labels: {
            datetimeUTC: false,
            formatter: (val) => {
                return formatXAxisTimestampBasedOnGranularity(granularity, val);
            },
        },
    },
    tooltip: {
        x: {
            formatter: (val) =>
                formatTooltipTimestampBasedOnGranularity(granularity, val),
        },
    },
    yaxis: [
        {
            tickAmount: 4,
            min: 0,
            // TODO Double check if this is depreciated
            // 'type: "numeric",'
            labels: {
                formatter: (num) => {
                    return currencyFormatter(num);
                },
            },
        },
        {
            opposite: true,
            tickAmount: 4,
            labels: {
                formatter: (num) => {
                    return numFormatter(num);
                },
            },
        },
    ],
    stroke: {
        curve: ["smooth", "smooth", "stepline"],
        width: [
            window.innerWidth <= MOBILE_VIEWPORT_WIDTH
                ? 2
                : getStrokeWidthBasedOnGranularity(granularity),
            window.innerWidth <= MOBILE_VIEWPORT_WIDTH
                ? 2
                : getStrokeWidthBasedOnGranularity(granularity),
            3,
        ],
    },
    colors: ["#4b81e0", "#bbd5fb"],
});

export const calculatePercentageDifference = (
    originalValue: number,
    comparisonValue: number,
    invertTargetValue = false
): number =>
    ((originalValue - comparisonValue) /
        (!invertTargetValue ? originalValue : comparisonValue)) *
    100;

export const mapByAggregatePeriod = (
    data: SeriesRow[],
    medianLabel: string,
    valuesToMap: string[],
    valueLabels: string[]
): any => {
    const dict = {};
    data.forEach((row: any) => {
        const sortablePeriodMedian = row[medianLabel];
        if (dict[sortablePeriodMedian]) {
            valueLabels.forEach((label, index) => {
                const value = row[valuesToMap[index]];
                dict[sortablePeriodMedian][label].push(value);
            });
        } else {
            dict[sortablePeriodMedian] = {};
            valueLabels.forEach((label, index) => {
                const value = row[valuesToMap[index]];
                dict[sortablePeriodMedian][label] = [value];
            });
        }
    });
    return dict;
};

export const getMedian = (arr: number[]): number => {
    arr.sort();
    const len = arr.length;
    const mid = Math.ceil(len / 2);
    return len % 2 == 0 ? (arr[mid] + arr[mid - 1]) / 2 : arr[mid - 1];
};

export const calculateAggregateResultSet = (
    filters: Filters,
    resultSet: ResultSet,
    medianLabel: string,
    valuesToMap: string[],
    valueLabels: string[]
): any => {
    const dict = mapByAggregatePeriod(
        resultSet.rawData(),
        medianLabel,
        valuesToMap,
        valueLabels
    );
    const xAxis = Object.keys(dict);
    const series: any = [valueLabels.map(() => [])];
    xAxis.forEach((x) => {
        valueLabels.forEach((label, index) => {
            const median = getMedian(dict[x][label]);
            series[0][index].push(median);
        });
    });
    return {
        xAxis: xAxis.map(
            (val) =>
                moment().subtract(1, "d").format("YYYY") +
                moment(val).format("-MM-DDTHH:mm:ss.SSS")
        ),
        series,
    };
};

export const stringSort = (a: string, b: string): number => {
    const nameA = a.toUpperCase(); // ignore upper and lowercase
    const nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    // names must be equal
    return 0;
};

export const datetimeSort = (
    a: moment.Moment | string,
    b: moment.Moment | string
): number => {
    let aDateObj = a as moment.Moment;
    let bDateObj = b as moment.Moment;
    if (typeof a === "string") {
        aDateObj = moment(a);
    }
    if (typeof b === "string") {
        bDateObj = moment(b);
    }

    if (aDateObj.isBefore(bDateObj)) {
        return -1;
    }
    if (aDateObj.isAfter(bDateObj)) {
        return 1;
    }
    return 0;
};

/**
 * Helper function to wrap a string in double-sided brackets.
 *
 * @param input
 * @returns
 */
export const bracketStringWrapping = (input: string): string => `\x00 (${input})`; // Note: the \x00 seems hacky but it is the make sure the spaces aren't auto trimmed.

export const isNumber = (value: number | string | undefined): boolean => {
    return typeof value === "number" && isFinite(value);
};

export const sortNumbersWithPossibleEmpty = (
    a: number | string,
    b: number | string
): number => {
    if (isNumber(a) && isNumber(b)) {
        return (a as number) - (b as number);
    } else if (!isNumber(a) && isNumber(b)) {
        return -1;
    } else if (!isNumber(b) && isNumber(a)) {
        return 1;
    } else {
        return 0;
    }
};

// ENUM for converting string values of Days into expected NUM value for Cube.js
export enum DAYS_OF_WEEK_NUM_VALUE {
    "Monday" = "1",
    "Tuesday" = "2",
    "Wednesday" = "3",
    "Thursday" = "4",
    "Friday" = "5",
    "Saturday" = "6",
    "Sunday" = "7",
}

export const DAYS_OF_THE_WEEK: DAYS_OF_WEEK[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
];
