import { Slider } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { AddHoursToFilterAction } from "../../State/actions";
import { Context, Filters } from "../../State/store";

const marks = {};

const formatTimeTo12Hour = (time) =>
    time >= 12 ? `${time % 12 || 12}pm` : `${time % 12 || 12}am`;

const formatTimeSliderTooltip = (num) => formatTimeTo12Hour(num % 24);

for (let i = 6; i < 30; i += 6) {
    const time = i % 24;
    marks[i] = formatTimeTo12Hour(time);
}

const hourMappings = {};
for (let i = 6; i < 30; i++) {
    hourMappings[i % 24] = i;
}

export default function TimeSlider(): ReactElement {
    const [values, setValues] = useState<[number, number] | undefined>([6, 29]);
    const [state, dispatch] = React.useContext(Context);
    const { selectedHours }: Filters = state.filters;
    const onAfterChange = (range) => {
        const [min, max] = range;
        const selectedHours: number[] = [];
        if (range !== [6, 29]) {
            for (let i = min; i < max; i++) {
                selectedHours.push(i % 24);
            }
        }
        const action: AddHoursToFilterAction = {
            type: "ADD_HOURS_TO_FILTER",
            payload: {
                selectedHours,
            },
        };
        dispatch(action);
    };

    useEffect(() => {
        if (selectedHours.length > 0) {
            const mappedHours = selectedHours.map((hour) => hourMappings[hour]);
            setValues([mappedHours[0], mappedHours[mappedHours.length - 1] + 1]);
        } else {
            setValues([6, 29]);
        }
    }, [selectedHours]);

    return (
        <Slider
            marks={marks}
            min={6}
            max={29}
            range={true}
            value={values}
            tipFormatter={formatTimeSliderTooltip}
            onAfterChange={onAfterChange}
            onChange={([a, b]) => setValues([a, b])}
        />
    );
}
