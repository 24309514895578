import TopNavigation from "../Components/TopNavigation";
import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { Content } from "antd/es/layout/layout";
import { Layout } from "antd";
import HomeDashboardMobile from "../Components/HomeDashboardMobile";
import HomeDashboardDesktop from "../Components/HomeDashboardDesktop";
import BaseDesktopPage from "./BaseDesktopPage";
import { useDebounceEffect } from "ahooks";

const GreyBackgroundDiv = styled.div`
    background: #f0f2f5;
`;

const MarginContentMobile = styled(Content)`
    margin: 10px;
`;

export default function HomePage(): ReactElement {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(windowWidth <= 500);
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);

        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    useDebounceEffect(() => {
        setIsMobile(windowWidth <= 500);
    }, [windowWidth]);

    return (
        <>
            {isMobile ? (
                <GreyBackgroundDiv>
                    <TopNavigation mode={"mobile"} />
                    <MarginContentMobile>
                        <HomeDashboardMobile />
                    </MarginContentMobile>
                    <Layout.Footer style={{ textAlign: "center" }}>
                        Quantaco ©2021 All rights reserved
                    </Layout.Footer>
                </GreyBackgroundDiv>
            ) : (
                <BaseDesktopPage page={"home"}>
                    <HomeDashboardDesktop />
                </BaseDesktopPage>
            )}
        </>
    );
}
