import { CubeQueryBuilder, todayIncludedInSelectedDateRange } from "Api/Cube/utils";
import { cubejsApi } from "Components/CubeJsProvider";
import { useQuery } from "react-query";
import { DEFAULT_DATE_FORMAT, parseToMoment } from "Utils/date-utils";
import { Maybe } from "types";
import { useContext } from "react";
import { Context } from "State/store";

export const useGetLastCutOffTime = (): {
    cutOffTime: Maybe<{
        hours: number;
        minutes: number;
    }>;
    isLoading: boolean;
} => {
    const [{ filters }] = useContext(Context);
    const transactionTotalKey = "actuals.transactionTotal";
    const periodKey = "actuals.period";

    const today = parseToMoment();
    const todaySelected = todayIncludedInSelectedDateRange(filters);

    const query = CubeQueryBuilder()
        .addMeasures([transactionTotalKey])
        .addTimeDimensions([
            {
                dimension: periodKey,
                granularity: "minute",
                dateRange: [
                    today.format(DEFAULT_DATE_FORMAT),
                    today.clone().add(1, "day").format(DEFAULT_DATE_FORMAT),
                ],
            },
        ])
        .addOrder({
            [periodKey]: "desc",
        })
        .addLimit(1)
        .getResult();

    const result = useQuery(
        ["last-cut-off-time", query],
        () => cubejsApi.load(query),
        {
            enabled: todaySelected,
            select: (result) => {
                const rawData = result.rawData();
                if (!rawData[0]) return parseToMoment().subtract(30, "minutes");
                return parseToMoment(result.rawData()[0][periodKey]);
            },
            staleTime: 0,
        }
    );

    return {
        isLoading: result.isLoading,
        cutOffTime:
            result.data && todaySelected
                ? {
                      hours: result.data.get("hours"),
                      minutes: result.data.get("minutes"),
                  }
                : undefined,
    };
};
