import React, { ReactElement, useEffect, useState } from "react";
import { Affix, Button, Layout, Result } from "antd";
import TopNavigation from "../Components/TopNavigation";
import styled from "styled-components";
import { Content } from "antd/es/layout/layout";
import BaseSideNavigation from "../Components/SideNavigation/BaseSideNavigation";
import SideNavigation from "../Components/SideNavigation";
import ForecastingSideNavigation from "../Components/ForecastingSideNavigation";
import { animated, useSpring } from "react-spring";
import { ErrorBoundary } from "react-error-boundary";
import { RoutePaths } from "../Components/Router/Routes";
import { ENV } from "../Utils/constants";

const AnimatedContent = animated(Content);

const MarginContent = styled(AnimatedContent)`
    margin: 20px;
`;

interface Props {
    children: ReactElement | ReactElement[];
    page: string;
}

const BaseDesktopPage = ({ children, page }: Props): ReactElement => {
    const [sideNav, setSideNav] = useState(<SideNavigation />);
    const styles = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
    });

    useEffect(() => {
        setSideNav(
            page === "home" ? <SideNavigation /> : <ForecastingSideNavigation />
        );
    }, [page]);

    return (
        <Layout style={{ minHeight: "100vh" }}>
            <BaseSideNavigation page={page}>{sideNav}</BaseSideNavigation>
            <Layout>
                <Affix>
                    <TopNavigation mode={"desktop"} page={page} />
                </Affix>
                <Layout.Content>
                    <ErrorBoundary
                        fallbackRender={({ error }) => (
                            <div>
                                <Result
                                    status="error"
                                    title="Something went wrong"
                                    // Only show extended error details in dev environments
                                    subTitle={
                                        ENV === "dev" ? (
                                            <details
                                                style={{ whiteSpace: "pre-wrap" }}
                                            >
                                                {error.message}
                                                <br />
                                                <br />
                                                {error.stack}
                                            </details>
                                        ) : null
                                    }
                                    extra={
                                        <Button
                                            type="primary"
                                            key="console"
                                            onClick={() => {
                                                // Route back to "forecasting" Home if already on a forecasting route.
                                                if (page == "forecasting") {
                                                    window.location.href =
                                                        RoutePaths.FORECASTING_LANDING;
                                                } else {
                                                    window.location.href =
                                                        RoutePaths.HOME;
                                                }
                                            }}
                                        >
                                            Go Home
                                        </Button>
                                    }
                                />
                            </div>
                        )}
                    >
                        <MarginContent style={styles}>{children}</MarginContent>
                    </ErrorBoundary>
                </Layout.Content>
                <Layout.Footer style={{ textAlign: "center" }}>
                    Quantaco ©2021 All rights reserved
                </Layout.Footer>
            </Layout>
        </Layout>
    );
};

export default BaseDesktopPage;
