import React, {
    Dispatch,
    ReactElement,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import {
    Button,
    Col,
    List,
    Popover,
    Row,
    Table,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import { ForecastDriver, Forecast } from "../../State/store";
import useCurrentForecast from "../../Hooks/useCurrentForecast";
import { ColumnType } from "antd/es/table";
import {
    CheckOutlined,
    CloseOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    UpOutlined,
} from "@ant-design/icons";
import { updateForecast } from "../../Api/backend";
import { DriverModal } from "./DriverModal";
import moment from "moment";

interface DriverDataType
    extends Omit<ForecastDriver, "value" | "valueType" | "spread"> {
    key: React.Key;
    actions: ReactElement;
    value: ["Flat" | "Percentage", number];
}

interface PopoverListProps {
    list: string[];
}

const formatValue = ([valueType, value]) => {
    if (valueType === "Flat") {
        return `$${value}`;
    }
    return `${value}%`;
};

const renderOverride = (override) =>
    override ? <CheckOutlined /> : <CloseOutlined />;

interface ActionsElementProps {
    driverId: string;
    setCurrentDriverId: Dispatch<SetStateAction<undefined | string>>;
    setModalVisible: Dispatch<SetStateAction<boolean>>;
    setCloneDriver: Dispatch<SetStateAction<boolean>>;
}

const ActionsElement = ({
    driverId,
    setCurrentDriverId,
    setModalVisible,
    setCloneDriver,
}: ActionsElementProps): ReactElement => {
    const { forecast, setCurrentForecast } = useCurrentForecast();

    const [tooltipVisible, setTooltipVisible] = useState<boolean | undefined>(false);
    const [popoverVisible, setPopoverVisible] = useState<boolean | undefined>(false);
    const [buttonLoading, setButtonLoading] = useState(false);

    const onEditButtonClick = () => {
        setModalVisible(true);
        setCloneDriver(false);
        setCurrentDriverId(driverId);
    };

    const onCloneButtonClick = () => {
        setModalVisible(true);
        setCloneDriver(true);
        setCurrentDriverId(driverId);
    };

    const onConfirmModalDeletion = () => {
        setButtonLoading(true);
        const updatedForecast: Forecast = {
            ...forecast,
            drivers: [
                ...forecast.drivers.filter((driver) => driver.id !== driverId),
            ],
        };

        updateForecast(updatedForecast).then(() => {
            setButtonLoading(false);
            setCurrentForecast(updatedForecast);
        });
    };

    return (
        <Row justify={"center"} gutter={8}>
            <Col>
                <Tooltip title={"edit"}>
                    <Button
                        type={"primary"}
                        onClick={onEditButtonClick}
                        icon={<EditOutlined />}
                        shape={"circle"}
                    />
                </Tooltip>
            </Col>
            <Col>
                <Tooltip title={"clone"}>
                    <Button
                        type={"default"}
                        onClick={onCloneButtonClick}
                        icon={<CopyOutlined />}
                        shape={"circle"}
                    />
                </Tooltip>
            </Col>
            <Col>
                <Tooltip
                    visible={tooltipVisible}
                    onVisibleChange={(visible) => setTooltipVisible(visible)}
                    title={"delete"}
                >
                    <Popover
                        placement={"topRight"}
                        visible={popoverVisible}
                        onVisibleChange={(visible) => {
                            setTooltipVisible(false);
                            setPopoverVisible(visible);
                        }}
                        trigger="click"
                        title={"Are you sure you want to delete this driver?"}
                        content={
                            <>
                                <Row gutter={8}>
                                    <Col span={12}>
                                        <Button
                                            loading={buttonLoading}
                                            block
                                            type={"primary"}
                                            icon={<CheckOutlined />}
                                            onClick={onConfirmModalDeletion}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <Button
                                            block
                                            type={"default"}
                                            onClick={() => setPopoverVisible(false)}
                                            icon={<CloseOutlined />}
                                        />
                                    </Col>
                                </Row>
                            </>
                        }
                    >
                        <Button danger icon={<DeleteOutlined />} shape={"circle"} />
                    </Popover>
                </Tooltip>
            </Col>
        </Row>
    );
};

const PopoverList = ({ list }: PopoverListProps) => {
    return (
        <>
            {list ? (
                <Row>
                    <Col flex={"auto"}>
                        <Typography.Text>{list[0]} </Typography.Text>
                    </Col>
                    {list.length > 1 && (
                        <Col>
                            <Popover
                                content={
                                    <List
                                        size={"small"}
                                        dataSource={list}
                                        renderItem={(item) => (
                                            <List.Item>{item}</List.Item>
                                        )}
                                    />
                                }
                            >
                                <Typography.Link>
                                    <UpOutlined />
                                </Typography.Link>
                            </Popover>
                        </Col>
                    )}{" "}
                </Row>
            ) : (
                <Typography.Text>Empty</Typography.Text>
            )}
        </>
    );
};

const getPopoverForList = (list) => {
    return <PopoverList list={list} />;
};

const renderTimePeriod = (timePeriod) => {
    const format = "DD/MM/YYYY HH:mm";
    const fromDateFormatted = moment(timePeriod.fromDate).format(format);
    const toDateFormatted = moment(timePeriod.toDate).format(format);
    return (
        <>
            <Tag style={{ width: "100%" }}>Start: {fromDateFormatted}</Tag>
            <Tag style={{ width: "100%" }}>End: {toDateFormatted}</Tag>
        </>
    );
};

const columns: ColumnType<DriverDataType>[] = [
    {
        key: "name",
        title: "Name",
        dataIndex: "name",
    },
    {
        key: "timePeriod",
        title: "Time Period",
        dataIndex: "timePeriod",
        render: (timePeriod) => renderTimePeriod(timePeriod),
        width: 25,
    },
    {
        key: "areas",
        title: "Areas",
        dataIndex: "areas",
        render: (list) => getPopoverForList(list),
    },
    {
        key: "classes",
        title: "Classes",
        dataIndex: "classes",
        render: (list) => getPopoverForList(list),
    },
    {
        key: "value",
        title: "Value",
        dataIndex: "value",
        render: formatValue,
    },
    {
        key: "override",
        title: "Override",
        dataIndex: "override",
        render: renderOverride,
    },
    {
        key: "actions",
        title: "Actions",
        dataIndex: "actions",
    },
];
export default function DriversTable(): ReactElement {
    const [currentDriverId, setCurrentDriverId] = useState<undefined | string>();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [cloneDriver, setCloneDriver] = useState<boolean>(false);
    const { forecast } = useCurrentForecast();

    const [rows, setRows] = useState<DriverDataType[]>([]);

    useEffect(() => {
        setRows(
            forecast.drivers.map((driver: ForecastDriver) => {
                return {
                    key: driver.id,
                    id: driver.id,
                    name: driver.name,
                    timePeriod: driver.timePeriod,
                    areas: driver.areas,
                    classes: driver.classes,
                    value: [driver.valueType, driver.value],
                    override: driver.override,
                    actions: (
                        <ActionsElement
                            driverId={driver.id}
                            setCurrentDriverId={setCurrentDriverId}
                            setModalVisible={setModalVisible}
                            setCloneDriver={setCloneDriver}
                        />
                    ),
                };
            })
        );
    }, [forecast]);

    return (
        <>
            <DriverModal
                existingDriver={forecast.drivers.find(
                    (driver) => driver.id === currentDriverId
                )}
                visible={modalVisible}
                setVisible={setModalVisible}
                cloneDriver={cloneDriver}
            />
            <Table
                columns={columns}
                dataSource={rows}
                pagination={{ pageSize: 5, hideOnSinglePage: true }}
            />
        </>
    );
}
