import React, { ReactElement } from "react";
import Tabs from "antd-mobile/lib/tabs";
import CumulativeTransactionsLineGraph from "../HomeDashboardDesktop/CumulativeTransactionsLineGraph";
import SalesOverTimeLineGraph from "../HomeDashboardDesktop/SalesOverTimeLineGraph";

const tabs = [{ title: "Cumulative Sales" }, { title: "Sales over Time" }];

export default function TabulatedGraphsMobile(): ReactElement {
    return (
        <Tabs tabs={tabs} initialPage={0} swipeable={false}>
            <CumulativeTransactionsLineGraph height={200} />
            <SalesOverTimeLineGraph height={200} />
        </Tabs>
    );
}
