import moment, { Moment } from "moment";

export const DEFAULT_GRANULARITY_IN_MINUTES = 15;
export const MINUTES_IN_HOURS = 60;
export const SECONDS_IN_MINUTE = 60;
export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";
export const HOUR_MINUTE_FORMAT = "HH:mm";

interface ShiftTimeInput {
    start: Moment;
    end: Moment;
    addDay?: boolean;
}

export type DateRange<T> = {
    start: T;
    end: T;
};

type DateFormat = "HH:mm" | "YYYY-MM-DD" | "YYYY-MM-DDTHH:mm:ss" | "ddd, HH:mm";

export const parseToMoment = (dateInString?: string | number): Moment =>
    moment(dateInString);

export const formatForDisplay = (
    dateInStringOrUnix?: string | number,
    dateFormate?: DateFormat
): string => parseToMoment(dateInStringOrUnix).format(dateFormate);

export const countNumberOfDaysBetweenDates = (start: string, end: string): number =>
    Math.floor(parseToMoment(end).diff(parseToMoment(start), "days")) + 1;

export const calculateNumberOfDaysAndWeeksSelectedInRange = (
    fromDate: string,
    toDate: string
): {
    numberOfDaysSelected: number;
    numberOfWeeksSelected: number;
} => {
    const numberOfDaysSelected = countNumberOfDaysBetweenDates(fromDate, toDate);
    const numberOfWeeksSelected = Math.floor(numberOfDaysSelected / 7);

    return { numberOfDaysSelected, numberOfWeeksSelected };
};

export const shiftToSamePeriodLastYear = ({
    start,
    end,
    addDay = false,
}: ShiftTimeInput): DateRange<string> => ({
    start: start.subtract(1, "year").format(DATE_TIME_FORMAT),
    end: end
        .subtract(1, "year")
        .add(addDay ? 1 : 0, "days")
        .format(DATE_TIME_FORMAT),
});

export const shiftToSameDayLastWeek = ({
    start,
    end,
    addDay = false,
}: ShiftTimeInput): DateRange<string> => ({
    start: start.subtract(7, "days").format(DATE_TIME_FORMAT),
    end: end
        .subtract(7, "days")
        .add(addDay ? 1 : 0, "days")
        .format(DATE_TIME_FORMAT),
});

export const shiftToPreviousPeriod = ({
    start,
    end,
    addDay = false,
}: ShiftTimeInput): DateRange<string> => {
    const daysDifference = end.diff(start, "days");
    return {
        start: start.subtract(daysDifference + 1, "day").format(DATE_TIME_FORMAT),
        end: end
            .subtract(daysDifference + 1, "day")
            .add(addDay ? 1 : 0, "days")
            .format(DATE_TIME_FORMAT),
    };
};
