import React, { ReactElement } from "react";
import { Col, Row, Typography } from "antd";
import { RoundedCard } from "./index";
import { SegmentTable } from "../SegmentTable/SegmentTable";

interface Props {
    children: ReactElement;
}

const ReducedPaddingCard = (props: Props) => (
    <RoundedCard
        {...props}
        style={{ minHeight: "380px" }}
        bodyStyle={{ padding: "10px" }}
    >
        {props.children}
    </RoundedCard>
);

export default function TableWidget(): ReactElement {
    return (
        <Row gutter={16}>
            <Col span={8}>
                <ReducedPaddingCard>
                    <SegmentTable segment="Venue" />
                </ReducedPaddingCard>
            </Col>
            <Col span={8}>
                <ReducedPaddingCard>
                    <SegmentTable segment="Area" />
                </ReducedPaddingCard>
            </Col>
            <Col span={8}>
                <ReducedPaddingCard>
                    <SegmentTable segment="Class" />
                </ReducedPaddingCard>
            </Col>
        </Row>
    );
}
