import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Context } from "../../State/store";
import { getGroupData, GroupData } from "../../Api/backend";
import jwtDecode from "jwt-decode";
import LandingPage from "./LandingPage";
import ForecastCreation from "./ForecastCreation";
import { useLocation, useParams } from "react-router-dom";
import { RoutePaths } from "../Router/Routes";

export interface ForecastPageParams {
    id: string;
}

export default function ForecastingDashboardDesktop(): ReactElement {
    const [loading, setLoading] = useState<boolean>(true);
    const [, dispatch] = useContext(Context);

    const { id } = useParams<ForecastPageParams>();

    const location = useLocation();

    useEffect(() => {
        setLoading(true);
        getGroupData().then((groupData: GroupData) => {
            const datasetName = (jwtDecode(groupData.cubejsToken) as any).user
                .dataset_name;
            const addGroupDataPayload = {
                groupData,
            };
            dispatch({ type: "ADD_GROUP_DATA", payload: addGroupDataPayload });

            console.log("addGroupDataPayload", addGroupDataPayload);
            // const updateFiltersPayload = {
            //     filters: {
            //         datasetName,
            //     },
            // };
            // dispatch({
            //     type: "UPDATE_FILTERS",
            //     payload: updateFiltersPayload,
            // });

            setLoading(false);
        });
    }, []);

    console.log("location.pathname ", location.pathname);
    console.log("RoutePaths.FORECASTING_LANDING", RoutePaths.FORECASTING_LANDING);

    // useEffect(() => {
    //     if (!loading && location.pathname === RoutePaths.FORECASTING_LANDING) {
    //         setLoading(true);
    //         getGroupData().then((groupData: GroupData) => {
    //             const datasetName = (jwtDecode(groupData.cubejsToken) as any).user
    //                 .dataset_name;
    //             const addGroupDataPayload = {
    //                 groupData,
    //             };
    //             dispatch({ type: "ADD_GROUP_DATA", payload: addGroupDataPayload });

    //             const updateFiltersPayload = {
    //                 filters: {
    //                     datasetName,
    //                 },
    //             };
    //             dispatch({
    //                 type: "UPDATE_FILTERS",
    //                 payload: updateFiltersPayload,
    //             });

    //             setLoading(false);
    //         });
    //     }
    // }, [location]);

    return (
        <>
            {!id ? (
                <LandingPage loading={loading} />
            ) : (
                <ForecastCreation loading={loading} />
            )}
        </>
    );
}
