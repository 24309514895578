import React, {
    Dispatch,
    ReactElement,
    useContext,
    useEffect,
    useState,
} from "react";
import BaselineChart, { BaselineRawRow } from "./BaselineChart";
import { Context, State } from "../../State/store";
import useCurrentForecast from "../../Hooks/useCurrentForecast";
import {
    Action,
    ToggleApplyDriverForecasts,
    ToggleCreateForecastBaseline,
} from "../../State/actions";
import { RoundedCard, WranglrDarkBlueTitle } from "../HomeDashboardDesktop";
import _ from "lodash";
import { Col } from "antd";
import SegmentTablesGroup from "./SegmentTablesGroup";
import moment from "moment";
import InfoTooltip from "../InfoTooltip";
import { BASELINE_AND_FORECAST_CARD_TOOLTIP_TEXT } from "../ForecastingDashboardDesktop/constants";

export const filterBaselineDataIfRequired =
    (
        filteredSegments: FilteredSegments,
        filterAreas: boolean,
        filterClasses: boolean,
        filterDays: boolean
    ) =>
    (row: BaselineRawRow): boolean => {
        const { days, areas, classes } = filteredSegments;
        return (
            (!filterAreas ||
                areas.length === 0 ||
                areas.includes(row.mapped_area) ||
                row.mapped_area === "") &&
            (!filterClasses ||
                classes.length === 0 ||
                classes.includes(row.mapped_class) ||
                row.mapped_class === "") &&
            (!filterDays ||
                days.length === 0 ||
                days.includes(moment(row.relative_timestamp).format("dddd")))
        );
    };

export interface FilteredSegments {
    days: string[];
    areas: string[];
    classes: string[];
}

const ForecastingChartsDataManager = (): ReactElement => {
    const [state, dispatch]: [State, Dispatch<Action>] = useContext(Context);
    const { toggleCreateBaseline, toggleApplyDriver } = state.forecastPage!;
    const { getBaselineData, getDriversData } = useCurrentForecast();
    const [loading, setLoading] = useState<boolean>(false);
    const [mode, setMode] = useState<"baseline" | "drivers">("baseline");
    const [baselineData, setBaselineData] = useState<BaselineRawRow[]>([]);
    const [originalBaselineData, setOriginalBaselineData] = useState<
        BaselineRawRow[]
    >([]);
    const [filteredSegments, setFilteredSegments] = useState<FilteredSegments>({
        days: [],
        areas: [],
        classes: [],
    });

    useEffect(() => {
        if (toggleCreateBaseline) {
            setMode("baseline");
            setLoading(true);
            getBaselineData()
                .then((data: BaselineRawRow[]) => {
                    setBaselineData(data.length ? data : []);
                    setOriginalBaselineData(data.length ? data : []);
                    setLoading(false);
                    const action: ToggleCreateForecastBaseline = {
                        type: "TOGGLE_CREATE_FORECAST_BASELINE",
                    };
                    dispatch(action);
                })
                .catch(() => {
                    setLoading(false);
                    const action: ToggleCreateForecastBaseline = {
                        type: "TOGGLE_CREATE_FORECAST_BASELINE",
                    };
                    dispatch(action);
                });
        }
    }, [toggleCreateBaseline]);

    useEffect(() => {
        if (toggleApplyDriver) {
            setMode("drivers");
            setLoading(true);
            getDriversData()
                .then((data: BaselineRawRow[]) => {
                    if (_.isEmpty(data)) {
                        setLoading(false);
                        return;
                    }

                    setBaselineData(data);
                    setOriginalBaselineData(data);
                    setLoading(false);
                    const action: ToggleApplyDriverForecasts = {
                        type: "TOGGLE_APPLY_DRIVER_FORECASTS",
                    };
                    dispatch(action);
                })
                .catch(() => {
                    setLoading(false);
                    const action: ToggleApplyDriverForecasts = {
                        type: "TOGGLE_APPLY_DRIVER_FORECASTS",
                    };
                    dispatch(action);
                });
        }
    }, [toggleApplyDriver]);

    useEffect(() => {
        if (originalBaselineData.length > 0) {
            setBaselineData(
                originalBaselineData.filter(
                    filterBaselineDataIfRequired(filteredSegments, true, true, true)
                )
            );
        }
    }, [filteredSegments]);

    return (
        <>
            <Col span={24}>
                <RoundedCard>
                    <WranglrDarkBlueTitle level={4}>
                        Baseline & Forecast{" "}
                        <InfoTooltip
                            tooltipText={BASELINE_AND_FORECAST_CARD_TOOLTIP_TEXT}
                        />
                    </WranglrDarkBlueTitle>
                    <BaselineChart
                        baselineData={baselineData}
                        loading={loading}
                        mode={mode}
                        filteredSegments={filteredSegments}
                    />
                </RoundedCard>
            </Col>

            <Col span={24}>
                <SegmentTablesGroup
                    baselineData={originalBaselineData}
                    loading={loading}
                    mode={mode}
                    setFilteredSegments={setFilteredSegments}
                    filteredSegments={filteredSegments}
                />
            </Col>
        </>
    );
};

export default ForecastingChartsDataManager;
