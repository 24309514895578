import {
    ReactElement,
    useContext,
    useEffect,
    useLayoutEffect,
    useState,
} from "react";
import { Route, RouteProps, useHistory, useLocation } from "react-router-dom";
import { firebaseApp } from "../../Utils/firebase";
import ControlPanel from "../CustomLoader";
import React from "react";
import { RoutePaths } from "./Routes";
import { getAuth } from "firebase/auth";
import { QuantacoLoader } from "Components/QuantacoLoader/QuantacoLoader";
import { getGroupData, GroupData } from "Api/backend";
import jwtDecode from "jwt-decode";
import { Context, Filters, SelectedDates } from "State/store";
import { presetRanges } from "Components/DateFilter";
import { prefetchTotalSalesAndStaffHours } from "Hooks/useGetTotalSalesAndStaffHoursByDates";
import { prefetchTotalSalesAndStaffHoursForComparison } from "Hooks/useGetTotalSalesAndStaffHoursForComparison";

/**
 * A Route wrapper component that checks for a valid user session within Firebase.
 *
 */
const AuthCheckRoute = ({
    component: Component,
    ...rest
}: RouteProps): ReactElement => {
    const history = useHistory();
    const location = useLocation();
    const [loaded, setLoaded] = useState(false);
    const [, dispatch] = useContext(Context);
    const auth = getAuth(firebaseApp);

    const isHomePage = location.pathname === "/";
    useLayoutEffect(() => {
        const authListen = auth.onAuthStateChanged((user) => {
            if (user === null) {
                if (isHomePage) {
                    // This is to improve the general UX.
                    // When a user visits to the root domain (e.g wranglr.com.au) it will show them a 403 screen.
                    // This is here to prevent that and instead automatically take the user to the login screen.
                    history.push(RoutePaths.LOGIN);
                } else {
                    // Push the user to the page not found which will provide them with some context & a link to the login portal.
                    history.push(RoutePaths.PAGE_NOT_FOUND);
                    setLoaded(true);
                }
            } else {
                if (isHomePage) {
                    getGroupData().then(async (groupData: GroupData) => {
                        const datasetName = (jwtDecode(groupData.cubejsToken) as any)
                            .user.dataset_name;

                        // TODO: Consider this dual dispatch event during state management upgrade.
                        dispatch({
                            type: "ADD_GROUP_DATA",
                            payload: {
                                groupData,
                            },
                        });

                        dispatch({
                            type: "UPDATE_FILTERS",
                            payload: {
                                filters: {
                                    datasetName,
                                    mode: "actual",
                                } as Filters,
                            },
                        });

                        if (
                            groupData.user &&
                            groupData.user.settings?.defaultViewId
                        ) {
                            const defaultViewId =
                                groupData.user.settings.defaultViewId;
                            const view = groupData.views.views.find(
                                (view) => view.view_id === defaultViewId
                            );
                            if (view) {
                                const selectedDates: SelectedDates =
                                    view.filters.selectedDates;
                                let { fromDate, toDate } = selectedDates;
                                if (selectedDates.isPresetDateRange) {
                                    const { presetDateRangeLabel } = selectedDates;
                                    const selectedRange = presetRanges.find(
                                        (range) =>
                                            range.label === presetDateRangeLabel
                                    )!;
                                    const [fromDateObj, toDateObj] =
                                        selectedRange.range;
                                    fromDate = fromDateObj.format("YYYY-MM-DD");
                                    toDate = toDateObj.format("YYYY-MM-DD");
                                }

                                const filters = {
                                    ...view.filters,
                                    selectedDates: {
                                        ...selectedDates,
                                        fromDate,
                                        toDate,
                                    },
                                    selectedViewId: defaultViewId,
                                };

                                await prefetchTotalSalesAndStaffHoursForComparison({
                                    ...filters,
                                    datasetName,
                                });
                                await prefetchTotalSalesAndStaffHours({
                                    ...filters,
                                    datasetName,
                                });

                                dispatch({
                                    type: "UPDATE_FILTERS",
                                    payload: {
                                        filters,
                                    },
                                });
                            }
                        }
                        setLoaded(true);
                    });
                } else {
                    setLoaded(true);
                }
            }
        });

        return () => {
            authListen(); // Unsubscribes the State listener on component de-mount.
        };
    }, [location]);

    return loaded ? (
        <Route {...rest} component={Component} />
    ) : (
        <QuantacoLoader size={200} />
    );
};

export default AuthCheckRoute;
