import { Tabs } from "antd";
import CumulativeTransactionsLineGraph from "./CumulativeTransactionsLineGraph";
import SalesOverTimeLineGraph from "./SalesOverTimeLineGraph";
import React, { ReactElement, useState } from "react";

interface Props {
    height: number;
}

export default function TabulatedGraphs({ height }: Props): ReactElement {
    const [activeTab, setActiveTab] = useState("1");
    return (
        <Tabs defaultActiveKey={"1"} onChange={(e) => setActiveTab(e)}>
            <Tabs.TabPane tab="Sales over Time" key="1">
                {activeTab === "1" && <SalesOverTimeLineGraph height={height} />}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Cumulative Sales" key="2">
                {activeTab === "2" && (
                    <CumulativeTransactionsLineGraph height={height} />
                )}
            </Tabs.TabPane>
        </Tabs>
    );
}
