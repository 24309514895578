import { PickerData } from "antd-mobile/es/picker/PropsType";
import List from "antd-mobile/lib/list";
import Picker from "antd-mobile/lib/picker";

import React, { ReactElement, useEffect, useState } from "react";
import { View } from "./index";

interface Props {
    className?: string;
    views: View[];
    onSelectView;
    selectedViewId: number | undefined;
    defaultViewId: number | null;
}

export default function ViewSelectMobile({
    className,
    views,
    onSelectView,
    selectedViewId,
    defaultViewId,
}: Props): ReactElement {
    const [pickerData, setPickerData] = useState<PickerData[]>([]);
    const [selectedView, setSelectedView] = useState<number[] | undefined>([]);

    useEffect(() => {
        const view: View | undefined = views.find(
            (view) => view.view_id === defaultViewId
        );
        if (view) {
            onSelectView(view);
        }
    }, []);

    useEffect(() => {
        setPickerData(
            views.map((view) => ({
                value: view.view_id,
                label:
                    view.view_id === defaultViewId
                        ? `${view.view_label} (default)`
                        : view.view_label,
            }))
        );
    }, [views]);

    const onChange = (value) => {
        const viewId = value[0];
        const view: View = views.find((view) => view.view_id === viewId)!;
        if (view === undefined) {
            //apply no filters if view not detected
            return;
        }
        onSelectView(view);
    };

    useEffect(() => {
        const view: View | undefined = views.find(
            (view) => view.view_id === selectedViewId
        );
        if (view) {
            setSelectedView([view.view_id]);
        } else {
            setSelectedView(undefined);
        }
    }, [selectedViewId]);

    return (
        <Picker
            cols={1}
            wrapStyle={{ width: "100%" }}
            data={pickerData}
            okText={"Confirm"}
            dismissText={"Cancel"}
            extra={"Select"}
            className={className}
            onChange={onChange}
            value={selectedView}
        >
            <List.Item arrow="horizontal">View Select</List.Item>
        </Picker>
    );
}
