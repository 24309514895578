import React, {
    Dispatch,
    ReactElement,
    useContext,
    useEffect,
    useState,
} from "react";
import { message } from "antd";
import {
    Context,
    Filters,
    initialState,
    SelectedDates,
    State,
} from "../../State/store";
import moment from "moment";
import { presetRanges } from "../DateFilter";
import ViewSelectDesktop from "./ViewSelectDesktop";
import ViewSelectMobile from "./ViewSelectMobile";
import { Action } from "../../State/actions";
import _ from "lodash";
import { Venue } from "../../Api/backend";

export interface View {
    view_id: number;
    view_label: string;
    date_created: string;
    filters: Filters;
}

export const EMPTY_FILTERS: Filters = {
    ...initialState.filters,
    selectedVenues: [],
    selectedAreas: [],
    selectedClasses: [],
    selectedDates: {
        fromDate: moment().format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
    },
    selectedDays: [],
    selectedHours: [],
    selectedAggregate: null,
    selectedComparison: null,
    mode: "actual",
    selectedViewId: undefined,
};

const calculateDynamicDate = (selectedDates: SelectedDates) => {
    const selectedPreset = presetRanges.find(
        (range) =>
            range.label.toLowerCase() ===
            selectedDates.presetDateRangeLabel!.toLowerCase()
    )!;
    const [fromDateObj, toDateObj] = selectedPreset.range;
    const fromDate = fromDateObj.format("YYYY-MM-DD");
    const toDate = toDateObj.format("YYYY-MM-DD");
    return { fromDate, toDate, presetDateRangeLabel: selectedPreset.label };
};

interface Props {
    className?: string;
    mode: "desktop" | "mobile";
}

export default function ViewSelect({ className, mode }: Props): ReactElement {
    const [state, dispatch]: [State, Dispatch<Action>] = useContext(Context);
    const { filters, groupData }: State = state;
    const { selectedViewId }: Filters = filters;
    const { forecasts } = groupData!;
    const { views } = state.groupData!.views;
    const [nonDeletableViews, setNonDeletableViews] = useState<View[]>([]);

    const onSelectView = (view: View) => {
        const payload = {
            filters: {
                ...view.filters,
                selectedViewId: view.view_id,
                datasetName: filters.datasetName,
                mode: view.filters.mode ? view.filters.mode : "actual",
            } as Filters,
        };
        if (view.filters.selectedDates.isPresetDateRange) {
            const { fromDate, toDate, presetDateRangeLabel } = calculateDynamicDate(
                view.filters.selectedDates
            );
            payload.filters.selectedDates = {
                fromDate,
                toDate,
                presetDateRangeLabel,
                isPresetDateRange: true,
            };
        }
        dispatch({ type: "UPDATE_FILTERS", payload });
        message.success("View Applied Sucessfully", 1);
    };

    const defaultViewId =
        groupData && groupData?.user && groupData.user.settings
            ? groupData.user.settings.defaultViewId
            : null;

    const onClear = () => {
        const payload = {
            filters: { ...EMPTY_FILTERS, datasetName: filters.datasetName },
        };
        dispatch({ type: "REPLACE_FILTERS", payload });
    };

    // A useEffect hook for non deletable views. This is for filters are may not be normally possible through the filter panel e.g. today view on forecasts.
    useEffect(() => {
        const maxId: number = _.max(views.map((view: View) => view.view_id))!;

        const venues: Venue[] = forecasts
            .filter((forecast) =>
                moment().isBetween(
                    moment(forecast.configuration.weekPeriod),
                    moment(forecast.configuration.weekPeriod).add(1, "week"),
                    "day",
                    "[]"
                )
            )
            .map((forecast) => ({ primary_id: forecast.configuration.venue }));

        setNonDeletableViews([
            {
                filters: {
                    ...initialState.filters,
                    selectedComparison: null,
                    selectedVenues: venues,
                    mode: "forecast",
                } as Filters,
                view_id: maxId + 1,
                view_label: "Today vs Forecast",
                date_created: moment().format(),
            },
        ]);
    }, [views]);

    return (
        <>
            {mode === "desktop" ? (
                <ViewSelectDesktop
                    views={nonDeletableViews.concat(views)}
                    onSelectView={onSelectView}
                    defaultViewId={defaultViewId}
                    onClear={onClear}
                    selectedViewId={selectedViewId}
                    className={className}
                />
            ) : (
                <ViewSelectMobile
                    className={className}
                    views={nonDeletableViews.concat(views)}
                    onSelectView={onSelectView}
                    selectedViewId={selectedViewId}
                    defaultViewId={defaultViewId}
                />
            )}
        </>
    );
}
