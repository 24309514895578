import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Context, State } from "../../State/store";
import Charts from "./ApexChartHandler";
import { Spin } from "antd";
import { getSalesOverTimeLineGraphOptions } from "../../Utils/utils";
import { useGetSaleAndStaffDataOverTime } from "Hooks/useGetSaleAndStaffDataOverTime";
import { getFormattingGranularity } from "Api/Cube/utils";
import { formatForDisplay } from "Utils/date-utils";
import { Aggregate } from "Components/AggregateFilter";

interface Props {
    height: number;
}

export default function SalesOverTimeLineGraph({ height }: Props): ReactElement {
    const [state] = useContext(Context);
    const { filters }: State = state;

    const { isLoading, data } = useGetSaleAndStaffDataOverTime();

    const [chartData, setChartData] = useState<any>({ options: {}, series: [] });

    useEffect(() => {
        const series = data?.length
            ? data.map((d) => {
                  const isTransactionalSeries = d.key.includes("transactionTotal");
                  return {
                      name: isTransactionalSeries ? "Sales" : "Active Staff",
                      type: isTransactionalSeries ? "line" : "column",
                      data: d.series.map((d) => d.value),
                  };
              })
            : [];

        const options = data?.length
            ? getSalesOverTimeLineGraphOptions(
                  data[0].series.map((d) => d.x),
                  getFormattingGranularity(filters)
              )
            : {};
        const averageAggregateSelected =
            filters.selectedAggregate === Aggregate.AVERAGE_WEEK ||
            filters.selectedAggregate === Aggregate.AVERAGE_DAY;

        if (data?.length && averageAggregateSelected) {
            const displayFormat =
                filters.selectedAggregate === Aggregate.AVERAGE_WEEK
                    ? "ddd, HH:mm"
                    : "HH:mm";

            const lineThickness =
                filters.selectedAggregate === Aggregate.AVERAGE_WEEK
                    ? [3, 3, 3]
                    : [5, 5, 3];

            options.xaxis = {
                type: "datetime",
                categories: data[0].series.map((d) => d.x),
                labels: {
                    datetimeUTC: false,
                    formatter: (val) => formatForDisplay(val, displayFormat),
                },
            };
            options.tooltip = {
                x: {
                    formatter: (val) => formatForDisplay(val, displayFormat),
                },
            };
            options.stroke = {
                ...options.stroke,
                width: lineThickness,
            };
        }

        setChartData({ options, series });
    }, [isLoading, data]);

    return (
        <div style={{ height }}>
            <Spin style={{ height }} spinning={isLoading}>
                {!isLoading && (
                    <Charts {...chartData} height={height} type={"line"} />
                )}
            </Spin>
        </div>
    );
}
