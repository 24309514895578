import { Context, State } from "../../State/store";
import React, { Dispatch, ReactElement, SetStateAction } from "react";
import CheckboxWithDrawers from "./index";
import { Action, AddAreasToFilterAction } from "../../State/actions";
import { drawerType } from "../SideNavigation/CheckboxManager";
interface Props {
    openDrawerState: drawerType;
    setOpenDrawerState: Dispatch<SetStateAction<drawerType>>;
}
export default function AreasCheckbox({
    openDrawerState,
    setOpenDrawerState,
}: Props): ReactElement {
    const [state, dispatch]: [State, Dispatch<Action>] = React.useContext(Context);
    const areas = state.groupData!.segments.areas;
    const selectedAreas = state.filters.selectedAreas.map((area) => area.primary_id);

    const dispatchAction = (selectedAreas: string[]): void => {
        const action: AddAreasToFilterAction = {
            type: "ADD_AREAS_TO_FILTER",
            payload: {
                selectedAreas: selectedAreas.map((area) => ({
                    primary_id: area,
                })),
            },
        };
        dispatch(action);
    };

    return (
        <>
            <CheckboxWithDrawers
                options={areas.map((area) => area.primary_id).sort()}
                dispatchAction={dispatchAction}
                selectedOptions={selectedAreas}
                openDrawerState={openDrawerState}
                setOpenDrawerState={setOpenDrawerState}
                drawerType={"Areas"}
                title={"Areas"}
            />
        </>
    );
}
